.position-absolute--full {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

@media (min-width: 768px) {
    .order-md-1 {
        order: 1;
    }

    .order-md-2 {
        order: 1;
    }
}

$sizes: ();
$sizes: map-merge(
    (
        0: 0,
        10: 10%,
        20: 20%,
        25: 25%,
        30: 30%,
        33: 33.3333%,
        40: 40%,
        50: 50%,
        60: 60%,
        66: 66.6666%,
        70: 70%,
        75: 75%,
        80: 80%,
        90: 90%,
        100: 100%
    ),
        $sizes
);

@each $size, $length in $sizes {
    .page-designer-reference {
        .w-#{$size} {
            width: $length;
        }

        .h-#{$size} {
            height: $length;
        }

        .top-#{$size} {
            top: $length;
        }

        .bottom-#{$size} {
            bottom: $length;
        }

        .left-#{$size} {
            left: $length;
        }

        .right-#{$size} {
            right: $length;
        }

        @include bp(min-width, 576) {
            .w-sm-#{$size} {
                width: $length !important;
            }

            .h-sm-#{$size} {
                height: $length !important;
            }

            .top-sm-#{$size} {
                top: $length !important;
            }

            .bottom-sm-#{$size} {
                bottom: $length !important;
            }

            .left-sm-#{$size} {
                left: $length !important;
            }

            .right-sm-#{$size} {
                right: $length !important;
            }
        }

        @include bp(min-width, $bp-medium) {
            .w-md-#{$size} {
                width: $length !important;
            }

            .h-md-#{$size} {
                height: $length !important;
            }

            .top-md-#{$size} {
                top: $length !important;
            }

            .bottom-md-#{$size} {
                bottom: $length !important;
            }

            .left-md-#{$size} {
                left: $length !important;
            }

            .right-md-#{$size} {
                right: $length !important;
            }
        }

        @include bp(min-width, 992px) {
            .w-lg-#{$size} {
                width: $length !important;
            }

            .h-lg-#{$size} {
                height: $length !important;
            }

            .top-lg-#{$size} {
                top: $length !important;
            }

            .bottom-lg-#{$size} {
                bottom: $length !important;
            }

            .left-lg-#{$size} {
                left: $length !important;
            }

            .right-lg-#{$size} {
                right: $length !important;
            }
        }
    }
}

@include bp(min-width, 1200px) {
    .modal-xl {
        min-width: 1200px !important;
    }
}

.overflow-visible {
    overflow: visible !important;
}
