$ex: ex-heading;

.experience-component {
    /* Title */
    .#{$ex} {
        color: $black;
        font: normal normal 36px/36px $f-classical-normal;
        text-transform: none;
        text-align: center;

        @include bp(min-width, $bp-medium) {
            font: normal normal 50px/50px $f-classical-normal;
        }

        [class*="--dark"] & {
            color: $white;
        }
    }

    /* Title -- dark */
    .#{$ex}--dark {
        color: $white;
    }

    /* Sub Title */
    .#{$ex}-sub {
        color: $black;
        font: normal normal 24px/24px $f-classical-normal;
        text-transform: none;

        @include bp(min-width, $bp-medium) {
            font: normal normal 30px/30px $f-classical-normal;
        }

        [class*="--dark"] & {
            color: $white;
        }
    }

    /* Sub Title -- dark */
    .#{$ex}-sub--dark {
        color: $white;
    }

    /* Sub Title -- Border */
    .#{$ex}-sub--border {
        &::after {
            border-bottom: 1px $black solid;
            content: " ";
            display: block;
            margin-top: 27px;
            width: 50px;
        }

        [class*="--dark"] & {
            &::after {
                border-color: $white;
            }
        }
    }

    /* Tile Title */
    .#{$ex}-tile {
        color: $black;
        font: normal normal 24px/24px $f-classical-normal;
        text-transform: none;

        @include bp(min-width, $bp-medium) {
            font: normal normal 30px/30px $f-classical-normal;
        }

        [class*="--dark"] & {
            color: $white;
        }
    }

    /* Tile Title -- dark */
    .#{$ex}-tile--dark {
        color: $white;
    }
}
