.light-color {
    color: $headers-color-light;
}

.dark-color {
    color: $headers-color-dark;
}

.h1-component-title,
.h2-component-title,
.h2-component-mobile-large-title,
.h3-component-title,
.paragraph-component {

    &.light-color {
        color: $headers-color-light;
    }

    &.dark-color {
        color: $headers-color-dark;
    }
}

.h1-component-title,
.h2-component-title,
.h2-component-mobile-large-title,
.h3-component-title {
    font-family: $f-classical-normal !important;
}

.h1-component-title {
    @include typography(
        $m-size: $h1-pd-font-size-mobile,
        $m-line: $h1-pd-line-height-mobile,
        $m-weight: $font-weight-pd-normal,
        $t-size: $h1-pd-font-size-desktop,
        $t-line: $h1-pd-line-height-desktop
    );
}

.h2-component-title {
    @include typography(
        $m-size: $h2-pd-font-size-mobile,
        $m-line: $h2-pd-line-height-mobile,
        $m-weight: $font-weight-pd-normal,
        $t-size: $h2-pd-font-size-desktop,
        $t-line: $h2-pd-line-height-desktop
    );
}

.h2-component-title-secondary {
    font-family: $f-classical !important;
    @include typography(
        $m-size: $h2-pd-font-size-mobile-sec,
        $m-line: $h2-pd-line-height-mobile,
        $t-size: $h2-pd-font-size-desktop-sec,
        $t-line: $h2-pd-line-height-desktop
    );
}


.h2-component-mobile-large-title {
    @include typography(
        $m-size: $h2-pd-font-size-desktop,
        $m-line: $h2-pd-line-height-desktop,
        $m-weight: $font-weight-pd-normal
    );
}

.h3-component-title {
    @include typography(
        $m-size: $h3-pd-font-size-mobile,
        $m-line: $h3-pd-line-height-mobile,
        $m-weight: $font-weight-pd-normal,
        $t-size: $h3-pd-font-size-desktop,
        $t-line: $h3-pd-line-height-desktop
    );
}

p,
span {
    font-family: $f-primary;
}

.paragraph-component {
    font-family: $f-primary !important;
    @include typography(
        $m-size: $paragraph-pd-font-size,
        $m-line: $paragraph-pd-line-height,
        $m-weight: $font-weight-pd-normal
    );
}

.paragraph-small-component {
    font-family: $f-primary;
    @include typography(
        $m-size: $paragraph-pd-font-size-mobile,
        $m-line: $paragraph-pd-line-height-mobile,
        $m-weight: $font-weight-pd-normal
    );
}

.paragraph-mobile-small-component {
    font-family: $f-primary;
    @include typography(
        $m-size: $paragraph-pd-font-size-mobile,
        $m-line: $paragraph-pd-line-height-mobile,
        $m-weight: $font-weight-pd-normal,
        $t-size: $paragraph-pd-font-size,
        $t-line: $paragraph-pd-line-height
    );
}


