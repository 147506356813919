.clearfix::after {
  content: "";
  display: table;
  clear: both; }

/*  To utilize the font mixin,

@mixin font-source-serif($size: false, $color: false, $weight: false,  $lh: false, $ls: false) {
@mixin font-source-serif-italic($size: false, $color: false, $weight: false,  $lh: false, $ls: false) {
@mixin font-source-sans($size: false, $color: false, $weight: false,  $lh: false, $ls: false) {

// @include font-source-serif(40px, $brand-v2-off-black, normal, 50px, null);
// @include font-source-sans(16px, $brand-v2-off-black, normal, 21px, 0.03em);

*/
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px; }

.display-none {
  display: none; }

.menu {
  float: left;
  margin: 0;
  padding: 0; }
  .menu li {
    float: left;
    list-style: none outside none !important; }

.text-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 99%; }

/* mixin for multiline */
.page-designer-reference .m-pd-auto {
  margin: auto; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-auto {
    margin: auto !important; } }

.page-designer-reference .p-pd-auto {
  padding: auto; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-auto {
    padding: auto !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-auto {
    margin-top: auto !important; } }

.page-designer-reference .mt-pd-auto {
  margin-top: auto; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-auto {
    padding-top: auto !important; } }

.page-designer-reference .pt-pd-auto {
  padding-top: auto; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-auto {
    margin-right: auto !important; } }

.page-designer-reference .mr-pd-auto {
  margin-right: auto; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-auto {
    padding-right: auto !important; } }

.page-designer-reference .pr-pd-auto {
  padding-right: auto; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-auto {
    margin-bottom: auto !important; } }

.page-designer-reference .mb-pd-auto {
  margin-bottom: auto; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-auto {
    padding-bottom: auto !important; } }

.page-designer-reference .pb-pd-auto {
  padding-bottom: auto; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-auto {
    margin-left: auto !important; } }

.page-designer-reference .ml-pd-auto {
  margin-left: auto; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-auto {
    padding-left: auto !important; } }

.page-designer-reference .pl-pd-auto {
  padding-left: auto; }

.page-designer-reference .m-pd-nauto {
  margin: -auto !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-nauto {
    margin: -auto !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-nauto {
    margin-top: -auto !important; } }

.page-designer-reference .mt-pd-nauto {
  margin-top: -auto !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-nauto {
    margin-right: -auto !important; } }

.page-designer-reference .mr-pd-nauto {
  margin-right: -auto !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-nauto {
    margin-bottom: -auto !important; } }

.page-designer-reference .mb-pd-nauto {
  margin-bottom: -auto !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-nauto {
    margin-left: -auto !important; } }

.page-designer-reference .ml-pd-nauto {
  margin-left: -auto !important; }

.page-designer-reference .m-pd-0 {
  margin: 0; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-0 {
    margin: 0 !important; } }

.page-designer-reference .p-pd-0 {
  padding: 0; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-0 {
    padding: 0 !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-0 {
    margin-top: 0 !important; } }

.page-designer-reference .mt-pd-0 {
  margin-top: 0; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-0 {
    padding-top: 0 !important; } }

.page-designer-reference .pt-pd-0 {
  padding-top: 0; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-0 {
    margin-right: 0 !important; } }

.page-designer-reference .mr-pd-0 {
  margin-right: 0; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-0 {
    padding-right: 0 !important; } }

.page-designer-reference .pr-pd-0 {
  padding-right: 0; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-0 {
    margin-bottom: 0 !important; } }

.page-designer-reference .mb-pd-0 {
  margin-bottom: 0; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-0 {
    padding-bottom: 0 !important; } }

.page-designer-reference .pb-pd-0 {
  padding-bottom: 0; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-0 {
    margin-left: 0 !important; } }

.page-designer-reference .ml-pd-0 {
  margin-left: 0; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-0 {
    padding-left: 0 !important; } }

.page-designer-reference .pl-pd-0 {
  padding-left: 0; }

.page-designer-reference .m-pd-n0 {
  margin: -0 !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n0 {
    margin: -0 !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n0 {
    margin-top: -0 !important; } }

.page-designer-reference .mt-pd-n0 {
  margin-top: -0 !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n0 {
    margin-right: -0 !important; } }

.page-designer-reference .mr-pd-n0 {
  margin-right: -0 !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n0 {
    margin-bottom: -0 !important; } }

.page-designer-reference .mb-pd-n0 {
  margin-bottom: -0 !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n0 {
    margin-left: -0 !important; } }

.page-designer-reference .ml-pd-n0 {
  margin-left: -0 !important; }

.page-designer-reference .m-pd-1 {
  margin: 0.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-1 {
    margin: 0.5rem !important; } }

.page-designer-reference .p-pd-1 {
  padding: 0.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-1 {
    padding: 0.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-1 {
    margin-top: 0.5rem !important; } }

.page-designer-reference .mt-pd-1 {
  margin-top: 0.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-1 {
    padding-top: 0.5rem !important; } }

.page-designer-reference .pt-pd-1 {
  padding-top: 0.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-1 {
    margin-right: 0.5rem !important; } }

.page-designer-reference .mr-pd-1 {
  margin-right: 0.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-1 {
    padding-right: 0.5rem !important; } }

.page-designer-reference .pr-pd-1 {
  padding-right: 0.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-1 {
    margin-bottom: 0.5rem !important; } }

.page-designer-reference .mb-pd-1 {
  margin-bottom: 0.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-1 {
    padding-bottom: 0.5rem !important; } }

.page-designer-reference .pb-pd-1 {
  padding-bottom: 0.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-1 {
    margin-left: 0.5rem !important; } }

.page-designer-reference .ml-pd-1 {
  margin-left: 0.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-1 {
    padding-left: 0.5rem !important; } }

.page-designer-reference .pl-pd-1 {
  padding-left: 0.5rem; }

.page-designer-reference .m-pd-n1 {
  margin: -0.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n1 {
    margin: -0.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n1 {
    margin-top: -0.5rem !important; } }

.page-designer-reference .mt-pd-n1 {
  margin-top: -0.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n1 {
    margin-right: -0.5rem !important; } }

.page-designer-reference .mr-pd-n1 {
  margin-right: -0.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n1 {
    margin-bottom: -0.5rem !important; } }

.page-designer-reference .mb-pd-n1 {
  margin-bottom: -0.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n1 {
    margin-left: -0.5rem !important; } }

.page-designer-reference .ml-pd-n1 {
  margin-left: -0.5rem !important; }

.page-designer-reference .m-pd-2 {
  margin: 1rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-2 {
    margin: 1rem !important; } }

.page-designer-reference .p-pd-2 {
  padding: 1rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-2 {
    padding: 1rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-2 {
    margin-top: 1rem !important; } }

.page-designer-reference .mt-pd-2 {
  margin-top: 1rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-2 {
    padding-top: 1rem !important; } }

.page-designer-reference .pt-pd-2 {
  padding-top: 1rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-2 {
    margin-right: 1rem !important; } }

.page-designer-reference .mr-pd-2 {
  margin-right: 1rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-2 {
    padding-right: 1rem !important; } }

.page-designer-reference .pr-pd-2 {
  padding-right: 1rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-2 {
    margin-bottom: 1rem !important; } }

.page-designer-reference .mb-pd-2 {
  margin-bottom: 1rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-2 {
    padding-bottom: 1rem !important; } }

.page-designer-reference .pb-pd-2 {
  padding-bottom: 1rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-2 {
    margin-left: 1rem !important; } }

.page-designer-reference .ml-pd-2 {
  margin-left: 1rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-2 {
    padding-left: 1rem !important; } }

.page-designer-reference .pl-pd-2 {
  padding-left: 1rem; }

.page-designer-reference .m-pd-n2 {
  margin: -1rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n2 {
    margin: -1rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n2 {
    margin-top: -1rem !important; } }

.page-designer-reference .mt-pd-n2 {
  margin-top: -1rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n2 {
    margin-right: -1rem !important; } }

.page-designer-reference .mr-pd-n2 {
  margin-right: -1rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n2 {
    margin-bottom: -1rem !important; } }

.page-designer-reference .mb-pd-n2 {
  margin-bottom: -1rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n2 {
    margin-left: -1rem !important; } }

.page-designer-reference .ml-pd-n2 {
  margin-left: -1rem !important; }

.page-designer-reference .m-pd-3 {
  margin: 1.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-3 {
    margin: 1.5rem !important; } }

.page-designer-reference .p-pd-3 {
  padding: 1.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-3 {
    padding: 1.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-3 {
    margin-top: 1.5rem !important; } }

.page-designer-reference .mt-pd-3 {
  margin-top: 1.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-3 {
    padding-top: 1.5rem !important; } }

.page-designer-reference .pt-pd-3 {
  padding-top: 1.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-3 {
    margin-right: 1.5rem !important; } }

.page-designer-reference .mr-pd-3 {
  margin-right: 1.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-3 {
    padding-right: 1.5rem !important; } }

.page-designer-reference .pr-pd-3 {
  padding-right: 1.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-3 {
    margin-bottom: 1.5rem !important; } }

.page-designer-reference .mb-pd-3 {
  margin-bottom: 1.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-3 {
    padding-bottom: 1.5rem !important; } }

.page-designer-reference .pb-pd-3 {
  padding-bottom: 1.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-3 {
    margin-left: 1.5rem !important; } }

.page-designer-reference .ml-pd-3 {
  margin-left: 1.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-3 {
    padding-left: 1.5rem !important; } }

.page-designer-reference .pl-pd-3 {
  padding-left: 1.5rem; }

.page-designer-reference .m-pd-n3 {
  margin: -1.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n3 {
    margin: -1.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n3 {
    margin-top: -1.5rem !important; } }

.page-designer-reference .mt-pd-n3 {
  margin-top: -1.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n3 {
    margin-right: -1.5rem !important; } }

.page-designer-reference .mr-pd-n3 {
  margin-right: -1.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n3 {
    margin-bottom: -1.5rem !important; } }

.page-designer-reference .mb-pd-n3 {
  margin-bottom: -1.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n3 {
    margin-left: -1.5rem !important; } }

.page-designer-reference .ml-pd-n3 {
  margin-left: -1.5rem !important; }

.page-designer-reference .m-pd-4 {
  margin: 2rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-4 {
    margin: 2rem !important; } }

.page-designer-reference .p-pd-4 {
  padding: 2rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-4 {
    padding: 2rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-4 {
    margin-top: 2rem !important; } }

.page-designer-reference .mt-pd-4 {
  margin-top: 2rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-4 {
    padding-top: 2rem !important; } }

.page-designer-reference .pt-pd-4 {
  padding-top: 2rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-4 {
    margin-right: 2rem !important; } }

.page-designer-reference .mr-pd-4 {
  margin-right: 2rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-4 {
    padding-right: 2rem !important; } }

.page-designer-reference .pr-pd-4 {
  padding-right: 2rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-4 {
    margin-bottom: 2rem !important; } }

.page-designer-reference .mb-pd-4 {
  margin-bottom: 2rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-4 {
    padding-bottom: 2rem !important; } }

.page-designer-reference .pb-pd-4 {
  padding-bottom: 2rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-4 {
    margin-left: 2rem !important; } }

.page-designer-reference .ml-pd-4 {
  margin-left: 2rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-4 {
    padding-left: 2rem !important; } }

.page-designer-reference .pl-pd-4 {
  padding-left: 2rem; }

.page-designer-reference .m-pd-n4 {
  margin: -2rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n4 {
    margin: -2rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n4 {
    margin-top: -2rem !important; } }

.page-designer-reference .mt-pd-n4 {
  margin-top: -2rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n4 {
    margin-right: -2rem !important; } }

.page-designer-reference .mr-pd-n4 {
  margin-right: -2rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n4 {
    margin-bottom: -2rem !important; } }

.page-designer-reference .mb-pd-n4 {
  margin-bottom: -2rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n4 {
    margin-left: -2rem !important; } }

.page-designer-reference .ml-pd-n4 {
  margin-left: -2rem !important; }

.page-designer-reference .m-pd-5 {
  margin: 2.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-5 {
    margin: 2.5rem !important; } }

.page-designer-reference .p-pd-5 {
  padding: 2.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-5 {
    padding: 2.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-5 {
    margin-top: 2.5rem !important; } }

.page-designer-reference .mt-pd-5 {
  margin-top: 2.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-5 {
    padding-top: 2.5rem !important; } }

.page-designer-reference .pt-pd-5 {
  padding-top: 2.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-5 {
    margin-right: 2.5rem !important; } }

.page-designer-reference .mr-pd-5 {
  margin-right: 2.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-5 {
    padding-right: 2.5rem !important; } }

.page-designer-reference .pr-pd-5 {
  padding-right: 2.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-5 {
    margin-bottom: 2.5rem !important; } }

.page-designer-reference .mb-pd-5 {
  margin-bottom: 2.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-5 {
    padding-bottom: 2.5rem !important; } }

.page-designer-reference .pb-pd-5 {
  padding-bottom: 2.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-5 {
    margin-left: 2.5rem !important; } }

.page-designer-reference .ml-pd-5 {
  margin-left: 2.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-5 {
    padding-left: 2.5rem !important; } }

.page-designer-reference .pl-pd-5 {
  padding-left: 2.5rem; }

.page-designer-reference .m-pd-n5 {
  margin: -2.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n5 {
    margin: -2.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n5 {
    margin-top: -2.5rem !important; } }

.page-designer-reference .mt-pd-n5 {
  margin-top: -2.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n5 {
    margin-right: -2.5rem !important; } }

.page-designer-reference .mr-pd-n5 {
  margin-right: -2.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n5 {
    margin-bottom: -2.5rem !important; } }

.page-designer-reference .mb-pd-n5 {
  margin-bottom: -2.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n5 {
    margin-left: -2.5rem !important; } }

.page-designer-reference .ml-pd-n5 {
  margin-left: -2.5rem !important; }

.page-designer-reference .m-pd-6 {
  margin: 3rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-6 {
    margin: 3rem !important; } }

.page-designer-reference .p-pd-6 {
  padding: 3rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-6 {
    padding: 3rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-6 {
    margin-top: 3rem !important; } }

.page-designer-reference .mt-pd-6 {
  margin-top: 3rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-6 {
    padding-top: 3rem !important; } }

.page-designer-reference .pt-pd-6 {
  padding-top: 3rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-6 {
    margin-right: 3rem !important; } }

.page-designer-reference .mr-pd-6 {
  margin-right: 3rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-6 {
    padding-right: 3rem !important; } }

.page-designer-reference .pr-pd-6 {
  padding-right: 3rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-6 {
    margin-bottom: 3rem !important; } }

.page-designer-reference .mb-pd-6 {
  margin-bottom: 3rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-6 {
    padding-bottom: 3rem !important; } }

.page-designer-reference .pb-pd-6 {
  padding-bottom: 3rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-6 {
    margin-left: 3rem !important; } }

.page-designer-reference .ml-pd-6 {
  margin-left: 3rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-6 {
    padding-left: 3rem !important; } }

.page-designer-reference .pl-pd-6 {
  padding-left: 3rem; }

.page-designer-reference .m-pd-n6 {
  margin: -3rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n6 {
    margin: -3rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n6 {
    margin-top: -3rem !important; } }

.page-designer-reference .mt-pd-n6 {
  margin-top: -3rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n6 {
    margin-right: -3rem !important; } }

.page-designer-reference .mr-pd-n6 {
  margin-right: -3rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n6 {
    margin-bottom: -3rem !important; } }

.page-designer-reference .mb-pd-n6 {
  margin-bottom: -3rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n6 {
    margin-left: -3rem !important; } }

.page-designer-reference .ml-pd-n6 {
  margin-left: -3rem !important; }

.page-designer-reference .m-pd-7 {
  margin: 3.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-7 {
    margin: 3.5rem !important; } }

.page-designer-reference .p-pd-7 {
  padding: 3.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-7 {
    padding: 3.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-7 {
    margin-top: 3.5rem !important; } }

.page-designer-reference .mt-pd-7 {
  margin-top: 3.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-7 {
    padding-top: 3.5rem !important; } }

.page-designer-reference .pt-pd-7 {
  padding-top: 3.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-7 {
    margin-right: 3.5rem !important; } }

.page-designer-reference .mr-pd-7 {
  margin-right: 3.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-7 {
    padding-right: 3.5rem !important; } }

.page-designer-reference .pr-pd-7 {
  padding-right: 3.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-7 {
    margin-bottom: 3.5rem !important; } }

.page-designer-reference .mb-pd-7 {
  margin-bottom: 3.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-7 {
    padding-bottom: 3.5rem !important; } }

.page-designer-reference .pb-pd-7 {
  padding-bottom: 3.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-7 {
    margin-left: 3.5rem !important; } }

.page-designer-reference .ml-pd-7 {
  margin-left: 3.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-7 {
    padding-left: 3.5rem !important; } }

.page-designer-reference .pl-pd-7 {
  padding-left: 3.5rem; }

.page-designer-reference .m-pd-n7 {
  margin: -3.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n7 {
    margin: -3.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n7 {
    margin-top: -3.5rem !important; } }

.page-designer-reference .mt-pd-n7 {
  margin-top: -3.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n7 {
    margin-right: -3.5rem !important; } }

.page-designer-reference .mr-pd-n7 {
  margin-right: -3.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n7 {
    margin-bottom: -3.5rem !important; } }

.page-designer-reference .mb-pd-n7 {
  margin-bottom: -3.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n7 {
    margin-left: -3.5rem !important; } }

.page-designer-reference .ml-pd-n7 {
  margin-left: -3.5rem !important; }

.page-designer-reference .m-pd-8 {
  margin: 4rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-8 {
    margin: 4rem !important; } }

.page-designer-reference .p-pd-8 {
  padding: 4rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-8 {
    padding: 4rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-8 {
    margin-top: 4rem !important; } }

.page-designer-reference .mt-pd-8 {
  margin-top: 4rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-8 {
    padding-top: 4rem !important; } }

.page-designer-reference .pt-pd-8 {
  padding-top: 4rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-8 {
    margin-right: 4rem !important; } }

.page-designer-reference .mr-pd-8 {
  margin-right: 4rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-8 {
    padding-right: 4rem !important; } }

.page-designer-reference .pr-pd-8 {
  padding-right: 4rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-8 {
    margin-bottom: 4rem !important; } }

.page-designer-reference .mb-pd-8 {
  margin-bottom: 4rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-8 {
    padding-bottom: 4rem !important; } }

.page-designer-reference .pb-pd-8 {
  padding-bottom: 4rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-8 {
    margin-left: 4rem !important; } }

.page-designer-reference .ml-pd-8 {
  margin-left: 4rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-8 {
    padding-left: 4rem !important; } }

.page-designer-reference .pl-pd-8 {
  padding-left: 4rem; }

.page-designer-reference .m-pd-n8 {
  margin: -4rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n8 {
    margin: -4rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n8 {
    margin-top: -4rem !important; } }

.page-designer-reference .mt-pd-n8 {
  margin-top: -4rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n8 {
    margin-right: -4rem !important; } }

.page-designer-reference .mr-pd-n8 {
  margin-right: -4rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n8 {
    margin-bottom: -4rem !important; } }

.page-designer-reference .mb-pd-n8 {
  margin-bottom: -4rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n8 {
    margin-left: -4rem !important; } }

.page-designer-reference .ml-pd-n8 {
  margin-left: -4rem !important; }

.page-designer-reference .m-pd-9 {
  margin: 4.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-9 {
    margin: 4.5rem !important; } }

.page-designer-reference .p-pd-9 {
  padding: 4.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-9 {
    padding: 4.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-9 {
    margin-top: 4.5rem !important; } }

.page-designer-reference .mt-pd-9 {
  margin-top: 4.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-9 {
    padding-top: 4.5rem !important; } }

.page-designer-reference .pt-pd-9 {
  padding-top: 4.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-9 {
    margin-right: 4.5rem !important; } }

.page-designer-reference .mr-pd-9 {
  margin-right: 4.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-9 {
    padding-right: 4.5rem !important; } }

.page-designer-reference .pr-pd-9 {
  padding-right: 4.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-9 {
    margin-bottom: 4.5rem !important; } }

.page-designer-reference .mb-pd-9 {
  margin-bottom: 4.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-9 {
    padding-bottom: 4.5rem !important; } }

.page-designer-reference .pb-pd-9 {
  padding-bottom: 4.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-9 {
    margin-left: 4.5rem !important; } }

.page-designer-reference .ml-pd-9 {
  margin-left: 4.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-9 {
    padding-left: 4.5rem !important; } }

.page-designer-reference .pl-pd-9 {
  padding-left: 4.5rem; }

.page-designer-reference .m-pd-n9 {
  margin: -4.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n9 {
    margin: -4.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n9 {
    margin-top: -4.5rem !important; } }

.page-designer-reference .mt-pd-n9 {
  margin-top: -4.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n9 {
    margin-right: -4.5rem !important; } }

.page-designer-reference .mr-pd-n9 {
  margin-right: -4.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n9 {
    margin-bottom: -4.5rem !important; } }

.page-designer-reference .mb-pd-n9 {
  margin-bottom: -4.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n9 {
    margin-left: -4.5rem !important; } }

.page-designer-reference .ml-pd-n9 {
  margin-left: -4.5rem !important; }

.page-designer-reference .m-pd-10 {
  margin: 5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-10 {
    margin: 5rem !important; } }

.page-designer-reference .p-pd-10 {
  padding: 5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-10 {
    padding: 5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-10 {
    margin-top: 5rem !important; } }

.page-designer-reference .mt-pd-10 {
  margin-top: 5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-10 {
    padding-top: 5rem !important; } }

.page-designer-reference .pt-pd-10 {
  padding-top: 5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-10 {
    margin-right: 5rem !important; } }

.page-designer-reference .mr-pd-10 {
  margin-right: 5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-10 {
    padding-right: 5rem !important; } }

.page-designer-reference .pr-pd-10 {
  padding-right: 5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-10 {
    margin-bottom: 5rem !important; } }

.page-designer-reference .mb-pd-10 {
  margin-bottom: 5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-10 {
    padding-bottom: 5rem !important; } }

.page-designer-reference .pb-pd-10 {
  padding-bottom: 5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-10 {
    margin-left: 5rem !important; } }

.page-designer-reference .ml-pd-10 {
  margin-left: 5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-10 {
    padding-left: 5rem !important; } }

.page-designer-reference .pl-pd-10 {
  padding-left: 5rem; }

.page-designer-reference .m-pd-n10 {
  margin: -5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n10 {
    margin: -5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n10 {
    margin-top: -5rem !important; } }

.page-designer-reference .mt-pd-n10 {
  margin-top: -5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n10 {
    margin-right: -5rem !important; } }

.page-designer-reference .mr-pd-n10 {
  margin-right: -5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n10 {
    margin-bottom: -5rem !important; } }

.page-designer-reference .mb-pd-n10 {
  margin-bottom: -5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n10 {
    margin-left: -5rem !important; } }

.page-designer-reference .ml-pd-n10 {
  margin-left: -5rem !important; }

.page-designer-reference .m-pd-11 {
  margin: 5.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-11 {
    margin: 5.5rem !important; } }

.page-designer-reference .p-pd-11 {
  padding: 5.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-11 {
    padding: 5.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-11 {
    margin-top: 5.5rem !important; } }

.page-designer-reference .mt-pd-11 {
  margin-top: 5.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-11 {
    padding-top: 5.5rem !important; } }

.page-designer-reference .pt-pd-11 {
  padding-top: 5.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-11 {
    margin-right: 5.5rem !important; } }

.page-designer-reference .mr-pd-11 {
  margin-right: 5.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-11 {
    padding-right: 5.5rem !important; } }

.page-designer-reference .pr-pd-11 {
  padding-right: 5.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-11 {
    margin-bottom: 5.5rem !important; } }

.page-designer-reference .mb-pd-11 {
  margin-bottom: 5.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-11 {
    padding-bottom: 5.5rem !important; } }

.page-designer-reference .pb-pd-11 {
  padding-bottom: 5.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-11 {
    margin-left: 5.5rem !important; } }

.page-designer-reference .ml-pd-11 {
  margin-left: 5.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-11 {
    padding-left: 5.5rem !important; } }

.page-designer-reference .pl-pd-11 {
  padding-left: 5.5rem; }

.page-designer-reference .m-pd-n11 {
  margin: -5.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n11 {
    margin: -5.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n11 {
    margin-top: -5.5rem !important; } }

.page-designer-reference .mt-pd-n11 {
  margin-top: -5.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n11 {
    margin-right: -5.5rem !important; } }

.page-designer-reference .mr-pd-n11 {
  margin-right: -5.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n11 {
    margin-bottom: -5.5rem !important; } }

.page-designer-reference .mb-pd-n11 {
  margin-bottom: -5.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n11 {
    margin-left: -5.5rem !important; } }

.page-designer-reference .ml-pd-n11 {
  margin-left: -5.5rem !important; }

.page-designer-reference .m-pd-12 {
  margin: 6rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-12 {
    margin: 6rem !important; } }

.page-designer-reference .p-pd-12 {
  padding: 6rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-12 {
    padding: 6rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-12 {
    margin-top: 6rem !important; } }

.page-designer-reference .mt-pd-12 {
  margin-top: 6rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-12 {
    padding-top: 6rem !important; } }

.page-designer-reference .pt-pd-12 {
  padding-top: 6rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-12 {
    margin-right: 6rem !important; } }

.page-designer-reference .mr-pd-12 {
  margin-right: 6rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-12 {
    padding-right: 6rem !important; } }

.page-designer-reference .pr-pd-12 {
  padding-right: 6rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-12 {
    margin-bottom: 6rem !important; } }

.page-designer-reference .mb-pd-12 {
  margin-bottom: 6rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-12 {
    padding-bottom: 6rem !important; } }

.page-designer-reference .pb-pd-12 {
  padding-bottom: 6rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-12 {
    margin-left: 6rem !important; } }

.page-designer-reference .ml-pd-12 {
  margin-left: 6rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-12 {
    padding-left: 6rem !important; } }

.page-designer-reference .pl-pd-12 {
  padding-left: 6rem; }

.page-designer-reference .m-pd-n12 {
  margin: -6rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n12 {
    margin: -6rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n12 {
    margin-top: -6rem !important; } }

.page-designer-reference .mt-pd-n12 {
  margin-top: -6rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n12 {
    margin-right: -6rem !important; } }

.page-designer-reference .mr-pd-n12 {
  margin-right: -6rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n12 {
    margin-bottom: -6rem !important; } }

.page-designer-reference .mb-pd-n12 {
  margin-bottom: -6rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n12 {
    margin-left: -6rem !important; } }

.page-designer-reference .ml-pd-n12 {
  margin-left: -6rem !important; }

.page-designer-reference .m-pd-13 {
  margin: 6.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-13 {
    margin: 6.5rem !important; } }

.page-designer-reference .p-pd-13 {
  padding: 6.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-13 {
    padding: 6.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-13 {
    margin-top: 6.5rem !important; } }

.page-designer-reference .mt-pd-13 {
  margin-top: 6.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-13 {
    padding-top: 6.5rem !important; } }

.page-designer-reference .pt-pd-13 {
  padding-top: 6.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-13 {
    margin-right: 6.5rem !important; } }

.page-designer-reference .mr-pd-13 {
  margin-right: 6.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-13 {
    padding-right: 6.5rem !important; } }

.page-designer-reference .pr-pd-13 {
  padding-right: 6.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-13 {
    margin-bottom: 6.5rem !important; } }

.page-designer-reference .mb-pd-13 {
  margin-bottom: 6.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-13 {
    padding-bottom: 6.5rem !important; } }

.page-designer-reference .pb-pd-13 {
  padding-bottom: 6.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-13 {
    margin-left: 6.5rem !important; } }

.page-designer-reference .ml-pd-13 {
  margin-left: 6.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-13 {
    padding-left: 6.5rem !important; } }

.page-designer-reference .pl-pd-13 {
  padding-left: 6.5rem; }

.page-designer-reference .m-pd-n13 {
  margin: -6.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n13 {
    margin: -6.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n13 {
    margin-top: -6.5rem !important; } }

.page-designer-reference .mt-pd-n13 {
  margin-top: -6.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n13 {
    margin-right: -6.5rem !important; } }

.page-designer-reference .mr-pd-n13 {
  margin-right: -6.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n13 {
    margin-bottom: -6.5rem !important; } }

.page-designer-reference .mb-pd-n13 {
  margin-bottom: -6.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n13 {
    margin-left: -6.5rem !important; } }

.page-designer-reference .ml-pd-n13 {
  margin-left: -6.5rem !important; }

.page-designer-reference .m-pd-14 {
  margin: 7rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-14 {
    margin: 7rem !important; } }

.page-designer-reference .p-pd-14 {
  padding: 7rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-14 {
    padding: 7rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-14 {
    margin-top: 7rem !important; } }

.page-designer-reference .mt-pd-14 {
  margin-top: 7rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-14 {
    padding-top: 7rem !important; } }

.page-designer-reference .pt-pd-14 {
  padding-top: 7rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-14 {
    margin-right: 7rem !important; } }

.page-designer-reference .mr-pd-14 {
  margin-right: 7rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-14 {
    padding-right: 7rem !important; } }

.page-designer-reference .pr-pd-14 {
  padding-right: 7rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-14 {
    margin-bottom: 7rem !important; } }

.page-designer-reference .mb-pd-14 {
  margin-bottom: 7rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-14 {
    padding-bottom: 7rem !important; } }

.page-designer-reference .pb-pd-14 {
  padding-bottom: 7rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-14 {
    margin-left: 7rem !important; } }

.page-designer-reference .ml-pd-14 {
  margin-left: 7rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-14 {
    padding-left: 7rem !important; } }

.page-designer-reference .pl-pd-14 {
  padding-left: 7rem; }

.page-designer-reference .m-pd-n14 {
  margin: -7rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n14 {
    margin: -7rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n14 {
    margin-top: -7rem !important; } }

.page-designer-reference .mt-pd-n14 {
  margin-top: -7rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n14 {
    margin-right: -7rem !important; } }

.page-designer-reference .mr-pd-n14 {
  margin-right: -7rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n14 {
    margin-bottom: -7rem !important; } }

.page-designer-reference .mb-pd-n14 {
  margin-bottom: -7rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n14 {
    margin-left: -7rem !important; } }

.page-designer-reference .ml-pd-n14 {
  margin-left: -7rem !important; }

.page-designer-reference .m-pd-15 {
  margin: 7.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-15 {
    margin: 7.5rem !important; } }

.page-designer-reference .p-pd-15 {
  padding: 7.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-15 {
    padding: 7.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-15 {
    margin-top: 7.5rem !important; } }

.page-designer-reference .mt-pd-15 {
  margin-top: 7.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-15 {
    padding-top: 7.5rem !important; } }

.page-designer-reference .pt-pd-15 {
  padding-top: 7.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-15 {
    margin-right: 7.5rem !important; } }

.page-designer-reference .mr-pd-15 {
  margin-right: 7.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-15 {
    padding-right: 7.5rem !important; } }

.page-designer-reference .pr-pd-15 {
  padding-right: 7.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-15 {
    margin-bottom: 7.5rem !important; } }

.page-designer-reference .mb-pd-15 {
  margin-bottom: 7.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-15 {
    padding-bottom: 7.5rem !important; } }

.page-designer-reference .pb-pd-15 {
  padding-bottom: 7.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-15 {
    margin-left: 7.5rem !important; } }

.page-designer-reference .ml-pd-15 {
  margin-left: 7.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-15 {
    padding-left: 7.5rem !important; } }

.page-designer-reference .pl-pd-15 {
  padding-left: 7.5rem; }

.page-designer-reference .m-pd-n15 {
  margin: -7.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n15 {
    margin: -7.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n15 {
    margin-top: -7.5rem !important; } }

.page-designer-reference .mt-pd-n15 {
  margin-top: -7.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n15 {
    margin-right: -7.5rem !important; } }

.page-designer-reference .mr-pd-n15 {
  margin-right: -7.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n15 {
    margin-bottom: -7.5rem !important; } }

.page-designer-reference .mb-pd-n15 {
  margin-bottom: -7.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n15 {
    margin-left: -7.5rem !important; } }

.page-designer-reference .ml-pd-n15 {
  margin-left: -7.5rem !important; }

.page-designer-reference .m-pd-16 {
  margin: 8rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-16 {
    margin: 8rem !important; } }

.page-designer-reference .p-pd-16 {
  padding: 8rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-16 {
    padding: 8rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-16 {
    margin-top: 8rem !important; } }

.page-designer-reference .mt-pd-16 {
  margin-top: 8rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-16 {
    padding-top: 8rem !important; } }

.page-designer-reference .pt-pd-16 {
  padding-top: 8rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-16 {
    margin-right: 8rem !important; } }

.page-designer-reference .mr-pd-16 {
  margin-right: 8rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-16 {
    padding-right: 8rem !important; } }

.page-designer-reference .pr-pd-16 {
  padding-right: 8rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-16 {
    margin-bottom: 8rem !important; } }

.page-designer-reference .mb-pd-16 {
  margin-bottom: 8rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-16 {
    padding-bottom: 8rem !important; } }

.page-designer-reference .pb-pd-16 {
  padding-bottom: 8rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-16 {
    margin-left: 8rem !important; } }

.page-designer-reference .ml-pd-16 {
  margin-left: 8rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-16 {
    padding-left: 8rem !important; } }

.page-designer-reference .pl-pd-16 {
  padding-left: 8rem; }

.page-designer-reference .m-pd-n16 {
  margin: -8rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n16 {
    margin: -8rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n16 {
    margin-top: -8rem !important; } }

.page-designer-reference .mt-pd-n16 {
  margin-top: -8rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n16 {
    margin-right: -8rem !important; } }

.page-designer-reference .mr-pd-n16 {
  margin-right: -8rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n16 {
    margin-bottom: -8rem !important; } }

.page-designer-reference .mb-pd-n16 {
  margin-bottom: -8rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n16 {
    margin-left: -8rem !important; } }

.page-designer-reference .ml-pd-n16 {
  margin-left: -8rem !important; }

.page-designer-reference .m-pd-17 {
  margin: 8.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-17 {
    margin: 8.5rem !important; } }

.page-designer-reference .p-pd-17 {
  padding: 8.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-17 {
    padding: 8.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-17 {
    margin-top: 8.5rem !important; } }

.page-designer-reference .mt-pd-17 {
  margin-top: 8.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-17 {
    padding-top: 8.5rem !important; } }

.page-designer-reference .pt-pd-17 {
  padding-top: 8.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-17 {
    margin-right: 8.5rem !important; } }

.page-designer-reference .mr-pd-17 {
  margin-right: 8.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-17 {
    padding-right: 8.5rem !important; } }

.page-designer-reference .pr-pd-17 {
  padding-right: 8.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-17 {
    margin-bottom: 8.5rem !important; } }

.page-designer-reference .mb-pd-17 {
  margin-bottom: 8.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-17 {
    padding-bottom: 8.5rem !important; } }

.page-designer-reference .pb-pd-17 {
  padding-bottom: 8.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-17 {
    margin-left: 8.5rem !important; } }

.page-designer-reference .ml-pd-17 {
  margin-left: 8.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-17 {
    padding-left: 8.5rem !important; } }

.page-designer-reference .pl-pd-17 {
  padding-left: 8.5rem; }

.page-designer-reference .m-pd-n17 {
  margin: -8.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n17 {
    margin: -8.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n17 {
    margin-top: -8.5rem !important; } }

.page-designer-reference .mt-pd-n17 {
  margin-top: -8.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n17 {
    margin-right: -8.5rem !important; } }

.page-designer-reference .mr-pd-n17 {
  margin-right: -8.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n17 {
    margin-bottom: -8.5rem !important; } }

.page-designer-reference .mb-pd-n17 {
  margin-bottom: -8.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n17 {
    margin-left: -8.5rem !important; } }

.page-designer-reference .ml-pd-n17 {
  margin-left: -8.5rem !important; }

.page-designer-reference .m-pd-18 {
  margin: 9rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-18 {
    margin: 9rem !important; } }

.page-designer-reference .p-pd-18 {
  padding: 9rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-18 {
    padding: 9rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-18 {
    margin-top: 9rem !important; } }

.page-designer-reference .mt-pd-18 {
  margin-top: 9rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-18 {
    padding-top: 9rem !important; } }

.page-designer-reference .pt-pd-18 {
  padding-top: 9rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-18 {
    margin-right: 9rem !important; } }

.page-designer-reference .mr-pd-18 {
  margin-right: 9rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-18 {
    padding-right: 9rem !important; } }

.page-designer-reference .pr-pd-18 {
  padding-right: 9rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-18 {
    margin-bottom: 9rem !important; } }

.page-designer-reference .mb-pd-18 {
  margin-bottom: 9rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-18 {
    padding-bottom: 9rem !important; } }

.page-designer-reference .pb-pd-18 {
  padding-bottom: 9rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-18 {
    margin-left: 9rem !important; } }

.page-designer-reference .ml-pd-18 {
  margin-left: 9rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-18 {
    padding-left: 9rem !important; } }

.page-designer-reference .pl-pd-18 {
  padding-left: 9rem; }

.page-designer-reference .m-pd-n18 {
  margin: -9rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n18 {
    margin: -9rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n18 {
    margin-top: -9rem !important; } }

.page-designer-reference .mt-pd-n18 {
  margin-top: -9rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n18 {
    margin-right: -9rem !important; } }

.page-designer-reference .mr-pd-n18 {
  margin-right: -9rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n18 {
    margin-bottom: -9rem !important; } }

.page-designer-reference .mb-pd-n18 {
  margin-bottom: -9rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n18 {
    margin-left: -9rem !important; } }

.page-designer-reference .ml-pd-n18 {
  margin-left: -9rem !important; }

.page-designer-reference .m-pd-19 {
  margin: 9.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-19 {
    margin: 9.5rem !important; } }

.page-designer-reference .p-pd-19 {
  padding: 9.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-19 {
    padding: 9.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-19 {
    margin-top: 9.5rem !important; } }

.page-designer-reference .mt-pd-19 {
  margin-top: 9.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-19 {
    padding-top: 9.5rem !important; } }

.page-designer-reference .pt-pd-19 {
  padding-top: 9.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-19 {
    margin-right: 9.5rem !important; } }

.page-designer-reference .mr-pd-19 {
  margin-right: 9.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-19 {
    padding-right: 9.5rem !important; } }

.page-designer-reference .pr-pd-19 {
  padding-right: 9.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-19 {
    margin-bottom: 9.5rem !important; } }

.page-designer-reference .mb-pd-19 {
  margin-bottom: 9.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-19 {
    padding-bottom: 9.5rem !important; } }

.page-designer-reference .pb-pd-19 {
  padding-bottom: 9.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-19 {
    margin-left: 9.5rem !important; } }

.page-designer-reference .ml-pd-19 {
  margin-left: 9.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-19 {
    padding-left: 9.5rem !important; } }

.page-designer-reference .pl-pd-19 {
  padding-left: 9.5rem; }

.page-designer-reference .m-pd-n19 {
  margin: -9.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n19 {
    margin: -9.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n19 {
    margin-top: -9.5rem !important; } }

.page-designer-reference .mt-pd-n19 {
  margin-top: -9.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n19 {
    margin-right: -9.5rem !important; } }

.page-designer-reference .mr-pd-n19 {
  margin-right: -9.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n19 {
    margin-bottom: -9.5rem !important; } }

.page-designer-reference .mb-pd-n19 {
  margin-bottom: -9.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n19 {
    margin-left: -9.5rem !important; } }

.page-designer-reference .ml-pd-n19 {
  margin-left: -9.5rem !important; }

.page-designer-reference .m-pd-20 {
  margin: 10rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-20 {
    margin: 10rem !important; } }

.page-designer-reference .p-pd-20 {
  padding: 10rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-20 {
    padding: 10rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-20 {
    margin-top: 10rem !important; } }

.page-designer-reference .mt-pd-20 {
  margin-top: 10rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-20 {
    padding-top: 10rem !important; } }

.page-designer-reference .pt-pd-20 {
  padding-top: 10rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-20 {
    margin-right: 10rem !important; } }

.page-designer-reference .mr-pd-20 {
  margin-right: 10rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-20 {
    padding-right: 10rem !important; } }

.page-designer-reference .pr-pd-20 {
  padding-right: 10rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-20 {
    margin-bottom: 10rem !important; } }

.page-designer-reference .mb-pd-20 {
  margin-bottom: 10rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-20 {
    padding-bottom: 10rem !important; } }

.page-designer-reference .pb-pd-20 {
  padding-bottom: 10rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-20 {
    margin-left: 10rem !important; } }

.page-designer-reference .ml-pd-20 {
  margin-left: 10rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-20 {
    padding-left: 10rem !important; } }

.page-designer-reference .pl-pd-20 {
  padding-left: 10rem; }

.page-designer-reference .m-pd-n20 {
  margin: -10rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n20 {
    margin: -10rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n20 {
    margin-top: -10rem !important; } }

.page-designer-reference .mt-pd-n20 {
  margin-top: -10rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n20 {
    margin-right: -10rem !important; } }

.page-designer-reference .mr-pd-n20 {
  margin-right: -10rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n20 {
    margin-bottom: -10rem !important; } }

.page-designer-reference .mb-pd-n20 {
  margin-bottom: -10rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n20 {
    margin-left: -10rem !important; } }

.page-designer-reference .ml-pd-n20 {
  margin-left: -10rem !important; }

.page-designer-reference .m-pd-21 {
  margin: 10.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-21 {
    margin: 10.5rem !important; } }

.page-designer-reference .p-pd-21 {
  padding: 10.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-21 {
    padding: 10.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-21 {
    margin-top: 10.5rem !important; } }

.page-designer-reference .mt-pd-21 {
  margin-top: 10.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-21 {
    padding-top: 10.5rem !important; } }

.page-designer-reference .pt-pd-21 {
  padding-top: 10.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-21 {
    margin-right: 10.5rem !important; } }

.page-designer-reference .mr-pd-21 {
  margin-right: 10.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-21 {
    padding-right: 10.5rem !important; } }

.page-designer-reference .pr-pd-21 {
  padding-right: 10.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-21 {
    margin-bottom: 10.5rem !important; } }

.page-designer-reference .mb-pd-21 {
  margin-bottom: 10.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-21 {
    padding-bottom: 10.5rem !important; } }

.page-designer-reference .pb-pd-21 {
  padding-bottom: 10.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-21 {
    margin-left: 10.5rem !important; } }

.page-designer-reference .ml-pd-21 {
  margin-left: 10.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-21 {
    padding-left: 10.5rem !important; } }

.page-designer-reference .pl-pd-21 {
  padding-left: 10.5rem; }

.page-designer-reference .m-pd-n21 {
  margin: -10.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n21 {
    margin: -10.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n21 {
    margin-top: -10.5rem !important; } }

.page-designer-reference .mt-pd-n21 {
  margin-top: -10.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n21 {
    margin-right: -10.5rem !important; } }

.page-designer-reference .mr-pd-n21 {
  margin-right: -10.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n21 {
    margin-bottom: -10.5rem !important; } }

.page-designer-reference .mb-pd-n21 {
  margin-bottom: -10.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n21 {
    margin-left: -10.5rem !important; } }

.page-designer-reference .ml-pd-n21 {
  margin-left: -10.5rem !important; }

.page-designer-reference .m-pd-22 {
  margin: 11rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-22 {
    margin: 11rem !important; } }

.page-designer-reference .p-pd-22 {
  padding: 11rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-22 {
    padding: 11rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-22 {
    margin-top: 11rem !important; } }

.page-designer-reference .mt-pd-22 {
  margin-top: 11rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-22 {
    padding-top: 11rem !important; } }

.page-designer-reference .pt-pd-22 {
  padding-top: 11rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-22 {
    margin-right: 11rem !important; } }

.page-designer-reference .mr-pd-22 {
  margin-right: 11rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-22 {
    padding-right: 11rem !important; } }

.page-designer-reference .pr-pd-22 {
  padding-right: 11rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-22 {
    margin-bottom: 11rem !important; } }

.page-designer-reference .mb-pd-22 {
  margin-bottom: 11rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-22 {
    padding-bottom: 11rem !important; } }

.page-designer-reference .pb-pd-22 {
  padding-bottom: 11rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-22 {
    margin-left: 11rem !important; } }

.page-designer-reference .ml-pd-22 {
  margin-left: 11rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-22 {
    padding-left: 11rem !important; } }

.page-designer-reference .pl-pd-22 {
  padding-left: 11rem; }

.page-designer-reference .m-pd-n22 {
  margin: -11rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n22 {
    margin: -11rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n22 {
    margin-top: -11rem !important; } }

.page-designer-reference .mt-pd-n22 {
  margin-top: -11rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n22 {
    margin-right: -11rem !important; } }

.page-designer-reference .mr-pd-n22 {
  margin-right: -11rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n22 {
    margin-bottom: -11rem !important; } }

.page-designer-reference .mb-pd-n22 {
  margin-bottom: -11rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n22 {
    margin-left: -11rem !important; } }

.page-designer-reference .ml-pd-n22 {
  margin-left: -11rem !important; }

.page-designer-reference .m-pd-23 {
  margin: 11.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-23 {
    margin: 11.5rem !important; } }

.page-designer-reference .p-pd-23 {
  padding: 11.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-23 {
    padding: 11.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-23 {
    margin-top: 11.5rem !important; } }

.page-designer-reference .mt-pd-23 {
  margin-top: 11.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-23 {
    padding-top: 11.5rem !important; } }

.page-designer-reference .pt-pd-23 {
  padding-top: 11.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-23 {
    margin-right: 11.5rem !important; } }

.page-designer-reference .mr-pd-23 {
  margin-right: 11.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-23 {
    padding-right: 11.5rem !important; } }

.page-designer-reference .pr-pd-23 {
  padding-right: 11.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-23 {
    margin-bottom: 11.5rem !important; } }

.page-designer-reference .mb-pd-23 {
  margin-bottom: 11.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-23 {
    padding-bottom: 11.5rem !important; } }

.page-designer-reference .pb-pd-23 {
  padding-bottom: 11.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-23 {
    margin-left: 11.5rem !important; } }

.page-designer-reference .ml-pd-23 {
  margin-left: 11.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-23 {
    padding-left: 11.5rem !important; } }

.page-designer-reference .pl-pd-23 {
  padding-left: 11.5rem; }

.page-designer-reference .m-pd-n23 {
  margin: -11.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n23 {
    margin: -11.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n23 {
    margin-top: -11.5rem !important; } }

.page-designer-reference .mt-pd-n23 {
  margin-top: -11.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n23 {
    margin-right: -11.5rem !important; } }

.page-designer-reference .mr-pd-n23 {
  margin-right: -11.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n23 {
    margin-bottom: -11.5rem !important; } }

.page-designer-reference .mb-pd-n23 {
  margin-bottom: -11.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n23 {
    margin-left: -11.5rem !important; } }

.page-designer-reference .ml-pd-n23 {
  margin-left: -11.5rem !important; }

.page-designer-reference .m-pd-24 {
  margin: 12rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-24 {
    margin: 12rem !important; } }

.page-designer-reference .p-pd-24 {
  padding: 12rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-24 {
    padding: 12rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-24 {
    margin-top: 12rem !important; } }

.page-designer-reference .mt-pd-24 {
  margin-top: 12rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-24 {
    padding-top: 12rem !important; } }

.page-designer-reference .pt-pd-24 {
  padding-top: 12rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-24 {
    margin-right: 12rem !important; } }

.page-designer-reference .mr-pd-24 {
  margin-right: 12rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-24 {
    padding-right: 12rem !important; } }

.page-designer-reference .pr-pd-24 {
  padding-right: 12rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-24 {
    margin-bottom: 12rem !important; } }

.page-designer-reference .mb-pd-24 {
  margin-bottom: 12rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-24 {
    padding-bottom: 12rem !important; } }

.page-designer-reference .pb-pd-24 {
  padding-bottom: 12rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-24 {
    margin-left: 12rem !important; } }

.page-designer-reference .ml-pd-24 {
  margin-left: 12rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-24 {
    padding-left: 12rem !important; } }

.page-designer-reference .pl-pd-24 {
  padding-left: 12rem; }

.page-designer-reference .m-pd-n24 {
  margin: -12rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n24 {
    margin: -12rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n24 {
    margin-top: -12rem !important; } }

.page-designer-reference .mt-pd-n24 {
  margin-top: -12rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n24 {
    margin-right: -12rem !important; } }

.page-designer-reference .mr-pd-n24 {
  margin-right: -12rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n24 {
    margin-bottom: -12rem !important; } }

.page-designer-reference .mb-pd-n24 {
  margin-bottom: -12rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n24 {
    margin-left: -12rem !important; } }

.page-designer-reference .ml-pd-n24 {
  margin-left: -12rem !important; }

.page-designer-reference .m-pd-25 {
  margin: 12.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-25 {
    margin: 12.5rem !important; } }

.page-designer-reference .p-pd-25 {
  padding: 12.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-25 {
    padding: 12.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-25 {
    margin-top: 12.5rem !important; } }

.page-designer-reference .mt-pd-25 {
  margin-top: 12.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-25 {
    padding-top: 12.5rem !important; } }

.page-designer-reference .pt-pd-25 {
  padding-top: 12.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-25 {
    margin-right: 12.5rem !important; } }

.page-designer-reference .mr-pd-25 {
  margin-right: 12.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-25 {
    padding-right: 12.5rem !important; } }

.page-designer-reference .pr-pd-25 {
  padding-right: 12.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-25 {
    margin-bottom: 12.5rem !important; } }

.page-designer-reference .mb-pd-25 {
  margin-bottom: 12.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-25 {
    padding-bottom: 12.5rem !important; } }

.page-designer-reference .pb-pd-25 {
  padding-bottom: 12.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-25 {
    margin-left: 12.5rem !important; } }

.page-designer-reference .ml-pd-25 {
  margin-left: 12.5rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-25 {
    padding-left: 12.5rem !important; } }

.page-designer-reference .pl-pd-25 {
  padding-left: 12.5rem; }

.page-designer-reference .m-pd-n25 {
  margin: -12.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n25 {
    margin: -12.5rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n25 {
    margin-top: -12.5rem !important; } }

.page-designer-reference .mt-pd-n25 {
  margin-top: -12.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n25 {
    margin-right: -12.5rem !important; } }

.page-designer-reference .mr-pd-n25 {
  margin-right: -12.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n25 {
    margin-bottom: -12.5rem !important; } }

.page-designer-reference .mb-pd-n25 {
  margin-bottom: -12.5rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n25 {
    margin-left: -12.5rem !important; } }

.page-designer-reference .ml-pd-n25 {
  margin-left: -12.5rem !important; }

.page-designer-reference .m-pd-26 {
  margin: 13rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-26 {
    margin: 13rem !important; } }

.page-designer-reference .p-pd-26 {
  padding: 13rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .p-md-pd-26 {
    padding: 13rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-26 {
    margin-top: 13rem !important; } }

.page-designer-reference .mt-pd-26 {
  margin-top: 13rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pt-md-pd-26 {
    padding-top: 13rem !important; } }

.page-designer-reference .pt-pd-26 {
  padding-top: 13rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-26 {
    margin-right: 13rem !important; } }

.page-designer-reference .mr-pd-26 {
  margin-right: 13rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pr-md-pd-26 {
    padding-right: 13rem !important; } }

.page-designer-reference .pr-pd-26 {
  padding-right: 13rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-26 {
    margin-bottom: 13rem !important; } }

.page-designer-reference .mb-pd-26 {
  margin-bottom: 13rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pb-md-pd-26 {
    padding-bottom: 13rem !important; } }

.page-designer-reference .pb-pd-26 {
  padding-bottom: 13rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-26 {
    margin-left: 13rem !important; } }

.page-designer-reference .ml-pd-26 {
  margin-left: 13rem; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .pl-md-pd-26 {
    padding-left: 13rem !important; } }

.page-designer-reference .pl-pd-26 {
  padding-left: 13rem; }

.page-designer-reference .m-pd-n26 {
  margin: -13rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .m-md-pd-n26 {
    margin: -13rem !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mt-md-pd-n26 {
    margin-top: -13rem !important; } }

.page-designer-reference .mt-pd-n26 {
  margin-top: -13rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mr-md-pd-n26 {
    margin-right: -13rem !important; } }

.page-designer-reference .mr-pd-n26 {
  margin-right: -13rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .mb-md-pd-n26 {
    margin-bottom: -13rem !important; } }

.page-designer-reference .mb-pd-n26 {
  margin-bottom: -13rem !important; }

@media only screen and (min-width: 768px) {
  .page-designer-reference .ml-md-pd-n26 {
    margin-left: -13rem !important; } }

.page-designer-reference .ml-pd-n26 {
  margin-left: -13rem !important; }

.position-absolute--full {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

@media (min-width: 768px) {
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 1; } }

.page-designer-reference .w-0 {
  width: 0; }

.page-designer-reference .h-0 {
  height: 0; }

.page-designer-reference .top-0 {
  top: 0; }

.page-designer-reference .bottom-0 {
  bottom: 0; }

.page-designer-reference .left-0 {
  left: 0; }

.page-designer-reference .right-0 {
  right: 0; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-0 {
    width: 0 !important; }
  .page-designer-reference .h-sm-0 {
    height: 0 !important; }
  .page-designer-reference .top-sm-0 {
    top: 0 !important; }
  .page-designer-reference .bottom-sm-0 {
    bottom: 0 !important; }
  .page-designer-reference .left-sm-0 {
    left: 0 !important; }
  .page-designer-reference .right-sm-0 {
    right: 0 !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-0 {
    width: 0 !important; }
  .page-designer-reference .h-md-0 {
    height: 0 !important; }
  .page-designer-reference .top-md-0 {
    top: 0 !important; }
  .page-designer-reference .bottom-md-0 {
    bottom: 0 !important; }
  .page-designer-reference .left-md-0 {
    left: 0 !important; }
  .page-designer-reference .right-md-0 {
    right: 0 !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-0 {
    width: 0 !important; }
  .page-designer-reference .h-lg-0 {
    height: 0 !important; }
  .page-designer-reference .top-lg-0 {
    top: 0 !important; }
  .page-designer-reference .bottom-lg-0 {
    bottom: 0 !important; }
  .page-designer-reference .left-lg-0 {
    left: 0 !important; }
  .page-designer-reference .right-lg-0 {
    right: 0 !important; } }

.page-designer-reference .w-10 {
  width: 10%; }

.page-designer-reference .h-10 {
  height: 10%; }

.page-designer-reference .top-10 {
  top: 10%; }

.page-designer-reference .bottom-10 {
  bottom: 10%; }

.page-designer-reference .left-10 {
  left: 10%; }

.page-designer-reference .right-10 {
  right: 10%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-10 {
    width: 10% !important; }
  .page-designer-reference .h-sm-10 {
    height: 10% !important; }
  .page-designer-reference .top-sm-10 {
    top: 10% !important; }
  .page-designer-reference .bottom-sm-10 {
    bottom: 10% !important; }
  .page-designer-reference .left-sm-10 {
    left: 10% !important; }
  .page-designer-reference .right-sm-10 {
    right: 10% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-10 {
    width: 10% !important; }
  .page-designer-reference .h-md-10 {
    height: 10% !important; }
  .page-designer-reference .top-md-10 {
    top: 10% !important; }
  .page-designer-reference .bottom-md-10 {
    bottom: 10% !important; }
  .page-designer-reference .left-md-10 {
    left: 10% !important; }
  .page-designer-reference .right-md-10 {
    right: 10% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-10 {
    width: 10% !important; }
  .page-designer-reference .h-lg-10 {
    height: 10% !important; }
  .page-designer-reference .top-lg-10 {
    top: 10% !important; }
  .page-designer-reference .bottom-lg-10 {
    bottom: 10% !important; }
  .page-designer-reference .left-lg-10 {
    left: 10% !important; }
  .page-designer-reference .right-lg-10 {
    right: 10% !important; } }

.page-designer-reference .w-20 {
  width: 20%; }

.page-designer-reference .h-20 {
  height: 20%; }

.page-designer-reference .top-20 {
  top: 20%; }

.page-designer-reference .bottom-20 {
  bottom: 20%; }

.page-designer-reference .left-20 {
  left: 20%; }

.page-designer-reference .right-20 {
  right: 20%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-20 {
    width: 20% !important; }
  .page-designer-reference .h-sm-20 {
    height: 20% !important; }
  .page-designer-reference .top-sm-20 {
    top: 20% !important; }
  .page-designer-reference .bottom-sm-20 {
    bottom: 20% !important; }
  .page-designer-reference .left-sm-20 {
    left: 20% !important; }
  .page-designer-reference .right-sm-20 {
    right: 20% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-20 {
    width: 20% !important; }
  .page-designer-reference .h-md-20 {
    height: 20% !important; }
  .page-designer-reference .top-md-20 {
    top: 20% !important; }
  .page-designer-reference .bottom-md-20 {
    bottom: 20% !important; }
  .page-designer-reference .left-md-20 {
    left: 20% !important; }
  .page-designer-reference .right-md-20 {
    right: 20% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-20 {
    width: 20% !important; }
  .page-designer-reference .h-lg-20 {
    height: 20% !important; }
  .page-designer-reference .top-lg-20 {
    top: 20% !important; }
  .page-designer-reference .bottom-lg-20 {
    bottom: 20% !important; }
  .page-designer-reference .left-lg-20 {
    left: 20% !important; }
  .page-designer-reference .right-lg-20 {
    right: 20% !important; } }

.page-designer-reference .w-25 {
  width: 25%; }

.page-designer-reference .h-25 {
  height: 25%; }

.page-designer-reference .top-25 {
  top: 25%; }

.page-designer-reference .bottom-25 {
  bottom: 25%; }

.page-designer-reference .left-25 {
  left: 25%; }

.page-designer-reference .right-25 {
  right: 25%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-25 {
    width: 25% !important; }
  .page-designer-reference .h-sm-25 {
    height: 25% !important; }
  .page-designer-reference .top-sm-25 {
    top: 25% !important; }
  .page-designer-reference .bottom-sm-25 {
    bottom: 25% !important; }
  .page-designer-reference .left-sm-25 {
    left: 25% !important; }
  .page-designer-reference .right-sm-25 {
    right: 25% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-25 {
    width: 25% !important; }
  .page-designer-reference .h-md-25 {
    height: 25% !important; }
  .page-designer-reference .top-md-25 {
    top: 25% !important; }
  .page-designer-reference .bottom-md-25 {
    bottom: 25% !important; }
  .page-designer-reference .left-md-25 {
    left: 25% !important; }
  .page-designer-reference .right-md-25 {
    right: 25% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-25 {
    width: 25% !important; }
  .page-designer-reference .h-lg-25 {
    height: 25% !important; }
  .page-designer-reference .top-lg-25 {
    top: 25% !important; }
  .page-designer-reference .bottom-lg-25 {
    bottom: 25% !important; }
  .page-designer-reference .left-lg-25 {
    left: 25% !important; }
  .page-designer-reference .right-lg-25 {
    right: 25% !important; } }

.page-designer-reference .w-30 {
  width: 30%; }

.page-designer-reference .h-30 {
  height: 30%; }

.page-designer-reference .top-30 {
  top: 30%; }

.page-designer-reference .bottom-30 {
  bottom: 30%; }

.page-designer-reference .left-30 {
  left: 30%; }

.page-designer-reference .right-30 {
  right: 30%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-30 {
    width: 30% !important; }
  .page-designer-reference .h-sm-30 {
    height: 30% !important; }
  .page-designer-reference .top-sm-30 {
    top: 30% !important; }
  .page-designer-reference .bottom-sm-30 {
    bottom: 30% !important; }
  .page-designer-reference .left-sm-30 {
    left: 30% !important; }
  .page-designer-reference .right-sm-30 {
    right: 30% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-30 {
    width: 30% !important; }
  .page-designer-reference .h-md-30 {
    height: 30% !important; }
  .page-designer-reference .top-md-30 {
    top: 30% !important; }
  .page-designer-reference .bottom-md-30 {
    bottom: 30% !important; }
  .page-designer-reference .left-md-30 {
    left: 30% !important; }
  .page-designer-reference .right-md-30 {
    right: 30% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-30 {
    width: 30% !important; }
  .page-designer-reference .h-lg-30 {
    height: 30% !important; }
  .page-designer-reference .top-lg-30 {
    top: 30% !important; }
  .page-designer-reference .bottom-lg-30 {
    bottom: 30% !important; }
  .page-designer-reference .left-lg-30 {
    left: 30% !important; }
  .page-designer-reference .right-lg-30 {
    right: 30% !important; } }

.page-designer-reference .w-33 {
  width: 33.3333%; }

.page-designer-reference .h-33 {
  height: 33.3333%; }

.page-designer-reference .top-33 {
  top: 33.3333%; }

.page-designer-reference .bottom-33 {
  bottom: 33.3333%; }

.page-designer-reference .left-33 {
  left: 33.3333%; }

.page-designer-reference .right-33 {
  right: 33.3333%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-33 {
    width: 33.3333% !important; }
  .page-designer-reference .h-sm-33 {
    height: 33.3333% !important; }
  .page-designer-reference .top-sm-33 {
    top: 33.3333% !important; }
  .page-designer-reference .bottom-sm-33 {
    bottom: 33.3333% !important; }
  .page-designer-reference .left-sm-33 {
    left: 33.3333% !important; }
  .page-designer-reference .right-sm-33 {
    right: 33.3333% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-33 {
    width: 33.3333% !important; }
  .page-designer-reference .h-md-33 {
    height: 33.3333% !important; }
  .page-designer-reference .top-md-33 {
    top: 33.3333% !important; }
  .page-designer-reference .bottom-md-33 {
    bottom: 33.3333% !important; }
  .page-designer-reference .left-md-33 {
    left: 33.3333% !important; }
  .page-designer-reference .right-md-33 {
    right: 33.3333% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-33 {
    width: 33.3333% !important; }
  .page-designer-reference .h-lg-33 {
    height: 33.3333% !important; }
  .page-designer-reference .top-lg-33 {
    top: 33.3333% !important; }
  .page-designer-reference .bottom-lg-33 {
    bottom: 33.3333% !important; }
  .page-designer-reference .left-lg-33 {
    left: 33.3333% !important; }
  .page-designer-reference .right-lg-33 {
    right: 33.3333% !important; } }

.page-designer-reference .w-40 {
  width: 40%; }

.page-designer-reference .h-40 {
  height: 40%; }

.page-designer-reference .top-40 {
  top: 40%; }

.page-designer-reference .bottom-40 {
  bottom: 40%; }

.page-designer-reference .left-40 {
  left: 40%; }

.page-designer-reference .right-40 {
  right: 40%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-40 {
    width: 40% !important; }
  .page-designer-reference .h-sm-40 {
    height: 40% !important; }
  .page-designer-reference .top-sm-40 {
    top: 40% !important; }
  .page-designer-reference .bottom-sm-40 {
    bottom: 40% !important; }
  .page-designer-reference .left-sm-40 {
    left: 40% !important; }
  .page-designer-reference .right-sm-40 {
    right: 40% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-40 {
    width: 40% !important; }
  .page-designer-reference .h-md-40 {
    height: 40% !important; }
  .page-designer-reference .top-md-40 {
    top: 40% !important; }
  .page-designer-reference .bottom-md-40 {
    bottom: 40% !important; }
  .page-designer-reference .left-md-40 {
    left: 40% !important; }
  .page-designer-reference .right-md-40 {
    right: 40% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-40 {
    width: 40% !important; }
  .page-designer-reference .h-lg-40 {
    height: 40% !important; }
  .page-designer-reference .top-lg-40 {
    top: 40% !important; }
  .page-designer-reference .bottom-lg-40 {
    bottom: 40% !important; }
  .page-designer-reference .left-lg-40 {
    left: 40% !important; }
  .page-designer-reference .right-lg-40 {
    right: 40% !important; } }

.page-designer-reference .w-50 {
  width: 50%; }

.page-designer-reference .h-50 {
  height: 50%; }

.page-designer-reference .top-50 {
  top: 50%; }

.page-designer-reference .bottom-50 {
  bottom: 50%; }

.page-designer-reference .left-50 {
  left: 50%; }

.page-designer-reference .right-50 {
  right: 50%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-50 {
    width: 50% !important; }
  .page-designer-reference .h-sm-50 {
    height: 50% !important; }
  .page-designer-reference .top-sm-50 {
    top: 50% !important; }
  .page-designer-reference .bottom-sm-50 {
    bottom: 50% !important; }
  .page-designer-reference .left-sm-50 {
    left: 50% !important; }
  .page-designer-reference .right-sm-50 {
    right: 50% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-50 {
    width: 50% !important; }
  .page-designer-reference .h-md-50 {
    height: 50% !important; }
  .page-designer-reference .top-md-50 {
    top: 50% !important; }
  .page-designer-reference .bottom-md-50 {
    bottom: 50% !important; }
  .page-designer-reference .left-md-50 {
    left: 50% !important; }
  .page-designer-reference .right-md-50 {
    right: 50% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-50 {
    width: 50% !important; }
  .page-designer-reference .h-lg-50 {
    height: 50% !important; }
  .page-designer-reference .top-lg-50 {
    top: 50% !important; }
  .page-designer-reference .bottom-lg-50 {
    bottom: 50% !important; }
  .page-designer-reference .left-lg-50 {
    left: 50% !important; }
  .page-designer-reference .right-lg-50 {
    right: 50% !important; } }

.page-designer-reference .w-60 {
  width: 60%; }

.page-designer-reference .h-60 {
  height: 60%; }

.page-designer-reference .top-60 {
  top: 60%; }

.page-designer-reference .bottom-60 {
  bottom: 60%; }

.page-designer-reference .left-60 {
  left: 60%; }

.page-designer-reference .right-60 {
  right: 60%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-60 {
    width: 60% !important; }
  .page-designer-reference .h-sm-60 {
    height: 60% !important; }
  .page-designer-reference .top-sm-60 {
    top: 60% !important; }
  .page-designer-reference .bottom-sm-60 {
    bottom: 60% !important; }
  .page-designer-reference .left-sm-60 {
    left: 60% !important; }
  .page-designer-reference .right-sm-60 {
    right: 60% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-60 {
    width: 60% !important; }
  .page-designer-reference .h-md-60 {
    height: 60% !important; }
  .page-designer-reference .top-md-60 {
    top: 60% !important; }
  .page-designer-reference .bottom-md-60 {
    bottom: 60% !important; }
  .page-designer-reference .left-md-60 {
    left: 60% !important; }
  .page-designer-reference .right-md-60 {
    right: 60% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-60 {
    width: 60% !important; }
  .page-designer-reference .h-lg-60 {
    height: 60% !important; }
  .page-designer-reference .top-lg-60 {
    top: 60% !important; }
  .page-designer-reference .bottom-lg-60 {
    bottom: 60% !important; }
  .page-designer-reference .left-lg-60 {
    left: 60% !important; }
  .page-designer-reference .right-lg-60 {
    right: 60% !important; } }

.page-designer-reference .w-66 {
  width: 66.6666%; }

.page-designer-reference .h-66 {
  height: 66.6666%; }

.page-designer-reference .top-66 {
  top: 66.6666%; }

.page-designer-reference .bottom-66 {
  bottom: 66.6666%; }

.page-designer-reference .left-66 {
  left: 66.6666%; }

.page-designer-reference .right-66 {
  right: 66.6666%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-66 {
    width: 66.6666% !important; }
  .page-designer-reference .h-sm-66 {
    height: 66.6666% !important; }
  .page-designer-reference .top-sm-66 {
    top: 66.6666% !important; }
  .page-designer-reference .bottom-sm-66 {
    bottom: 66.6666% !important; }
  .page-designer-reference .left-sm-66 {
    left: 66.6666% !important; }
  .page-designer-reference .right-sm-66 {
    right: 66.6666% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-66 {
    width: 66.6666% !important; }
  .page-designer-reference .h-md-66 {
    height: 66.6666% !important; }
  .page-designer-reference .top-md-66 {
    top: 66.6666% !important; }
  .page-designer-reference .bottom-md-66 {
    bottom: 66.6666% !important; }
  .page-designer-reference .left-md-66 {
    left: 66.6666% !important; }
  .page-designer-reference .right-md-66 {
    right: 66.6666% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-66 {
    width: 66.6666% !important; }
  .page-designer-reference .h-lg-66 {
    height: 66.6666% !important; }
  .page-designer-reference .top-lg-66 {
    top: 66.6666% !important; }
  .page-designer-reference .bottom-lg-66 {
    bottom: 66.6666% !important; }
  .page-designer-reference .left-lg-66 {
    left: 66.6666% !important; }
  .page-designer-reference .right-lg-66 {
    right: 66.6666% !important; } }

.page-designer-reference .w-70 {
  width: 70%; }

.page-designer-reference .h-70 {
  height: 70%; }

.page-designer-reference .top-70 {
  top: 70%; }

.page-designer-reference .bottom-70 {
  bottom: 70%; }

.page-designer-reference .left-70 {
  left: 70%; }

.page-designer-reference .right-70 {
  right: 70%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-70 {
    width: 70% !important; }
  .page-designer-reference .h-sm-70 {
    height: 70% !important; }
  .page-designer-reference .top-sm-70 {
    top: 70% !important; }
  .page-designer-reference .bottom-sm-70 {
    bottom: 70% !important; }
  .page-designer-reference .left-sm-70 {
    left: 70% !important; }
  .page-designer-reference .right-sm-70 {
    right: 70% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-70 {
    width: 70% !important; }
  .page-designer-reference .h-md-70 {
    height: 70% !important; }
  .page-designer-reference .top-md-70 {
    top: 70% !important; }
  .page-designer-reference .bottom-md-70 {
    bottom: 70% !important; }
  .page-designer-reference .left-md-70 {
    left: 70% !important; }
  .page-designer-reference .right-md-70 {
    right: 70% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-70 {
    width: 70% !important; }
  .page-designer-reference .h-lg-70 {
    height: 70% !important; }
  .page-designer-reference .top-lg-70 {
    top: 70% !important; }
  .page-designer-reference .bottom-lg-70 {
    bottom: 70% !important; }
  .page-designer-reference .left-lg-70 {
    left: 70% !important; }
  .page-designer-reference .right-lg-70 {
    right: 70% !important; } }

.page-designer-reference .w-75 {
  width: 75%; }

.page-designer-reference .h-75 {
  height: 75%; }

.page-designer-reference .top-75 {
  top: 75%; }

.page-designer-reference .bottom-75 {
  bottom: 75%; }

.page-designer-reference .left-75 {
  left: 75%; }

.page-designer-reference .right-75 {
  right: 75%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-75 {
    width: 75% !important; }
  .page-designer-reference .h-sm-75 {
    height: 75% !important; }
  .page-designer-reference .top-sm-75 {
    top: 75% !important; }
  .page-designer-reference .bottom-sm-75 {
    bottom: 75% !important; }
  .page-designer-reference .left-sm-75 {
    left: 75% !important; }
  .page-designer-reference .right-sm-75 {
    right: 75% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-75 {
    width: 75% !important; }
  .page-designer-reference .h-md-75 {
    height: 75% !important; }
  .page-designer-reference .top-md-75 {
    top: 75% !important; }
  .page-designer-reference .bottom-md-75 {
    bottom: 75% !important; }
  .page-designer-reference .left-md-75 {
    left: 75% !important; }
  .page-designer-reference .right-md-75 {
    right: 75% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-75 {
    width: 75% !important; }
  .page-designer-reference .h-lg-75 {
    height: 75% !important; }
  .page-designer-reference .top-lg-75 {
    top: 75% !important; }
  .page-designer-reference .bottom-lg-75 {
    bottom: 75% !important; }
  .page-designer-reference .left-lg-75 {
    left: 75% !important; }
  .page-designer-reference .right-lg-75 {
    right: 75% !important; } }

.page-designer-reference .w-80 {
  width: 80%; }

.page-designer-reference .h-80 {
  height: 80%; }

.page-designer-reference .top-80 {
  top: 80%; }

.page-designer-reference .bottom-80 {
  bottom: 80%; }

.page-designer-reference .left-80 {
  left: 80%; }

.page-designer-reference .right-80 {
  right: 80%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-80 {
    width: 80% !important; }
  .page-designer-reference .h-sm-80 {
    height: 80% !important; }
  .page-designer-reference .top-sm-80 {
    top: 80% !important; }
  .page-designer-reference .bottom-sm-80 {
    bottom: 80% !important; }
  .page-designer-reference .left-sm-80 {
    left: 80% !important; }
  .page-designer-reference .right-sm-80 {
    right: 80% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-80 {
    width: 80% !important; }
  .page-designer-reference .h-md-80 {
    height: 80% !important; }
  .page-designer-reference .top-md-80 {
    top: 80% !important; }
  .page-designer-reference .bottom-md-80 {
    bottom: 80% !important; }
  .page-designer-reference .left-md-80 {
    left: 80% !important; }
  .page-designer-reference .right-md-80 {
    right: 80% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-80 {
    width: 80% !important; }
  .page-designer-reference .h-lg-80 {
    height: 80% !important; }
  .page-designer-reference .top-lg-80 {
    top: 80% !important; }
  .page-designer-reference .bottom-lg-80 {
    bottom: 80% !important; }
  .page-designer-reference .left-lg-80 {
    left: 80% !important; }
  .page-designer-reference .right-lg-80 {
    right: 80% !important; } }

.page-designer-reference .w-90 {
  width: 90%; }

.page-designer-reference .h-90 {
  height: 90%; }

.page-designer-reference .top-90 {
  top: 90%; }

.page-designer-reference .bottom-90 {
  bottom: 90%; }

.page-designer-reference .left-90 {
  left: 90%; }

.page-designer-reference .right-90 {
  right: 90%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-90 {
    width: 90% !important; }
  .page-designer-reference .h-sm-90 {
    height: 90% !important; }
  .page-designer-reference .top-sm-90 {
    top: 90% !important; }
  .page-designer-reference .bottom-sm-90 {
    bottom: 90% !important; }
  .page-designer-reference .left-sm-90 {
    left: 90% !important; }
  .page-designer-reference .right-sm-90 {
    right: 90% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-90 {
    width: 90% !important; }
  .page-designer-reference .h-md-90 {
    height: 90% !important; }
  .page-designer-reference .top-md-90 {
    top: 90% !important; }
  .page-designer-reference .bottom-md-90 {
    bottom: 90% !important; }
  .page-designer-reference .left-md-90 {
    left: 90% !important; }
  .page-designer-reference .right-md-90 {
    right: 90% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-90 {
    width: 90% !important; }
  .page-designer-reference .h-lg-90 {
    height: 90% !important; }
  .page-designer-reference .top-lg-90 {
    top: 90% !important; }
  .page-designer-reference .bottom-lg-90 {
    bottom: 90% !important; }
  .page-designer-reference .left-lg-90 {
    left: 90% !important; }
  .page-designer-reference .right-lg-90 {
    right: 90% !important; } }

.page-designer-reference .w-100 {
  width: 100%; }

.page-designer-reference .h-100 {
  height: 100%; }

.page-designer-reference .top-100 {
  top: 100%; }

.page-designer-reference .bottom-100 {
  bottom: 100%; }

.page-designer-reference .left-100 {
  left: 100%; }

.page-designer-reference .right-100 {
  right: 100%; }

@media only screen and (min-width: 576) {
  .page-designer-reference .w-sm-100 {
    width: 100% !important; }
  .page-designer-reference .h-sm-100 {
    height: 100% !important; }
  .page-designer-reference .top-sm-100 {
    top: 100% !important; }
  .page-designer-reference .bottom-sm-100 {
    bottom: 100% !important; }
  .page-designer-reference .left-sm-100 {
    left: 100% !important; }
  .page-designer-reference .right-sm-100 {
    right: 100% !important; } }

@media only screen and (min-width: 768px) {
  .page-designer-reference .w-md-100 {
    width: 100% !important; }
  .page-designer-reference .h-md-100 {
    height: 100% !important; }
  .page-designer-reference .top-md-100 {
    top: 100% !important; }
  .page-designer-reference .bottom-md-100 {
    bottom: 100% !important; }
  .page-designer-reference .left-md-100 {
    left: 100% !important; }
  .page-designer-reference .right-md-100 {
    right: 100% !important; } }

@media only screen and (min-width: 992px) {
  .page-designer-reference .w-lg-100 {
    width: 100% !important; }
  .page-designer-reference .h-lg-100 {
    height: 100% !important; }
  .page-designer-reference .top-lg-100 {
    top: 100% !important; }
  .page-designer-reference .bottom-lg-100 {
    bottom: 100% !important; }
  .page-designer-reference .left-lg-100 {
    left: 100% !important; }
  .page-designer-reference .right-lg-100 {
    right: 100% !important; } }

@media only screen and (min-width: 1200px) {
  .modal-xl {
    min-width: 1200px !important; } }

.overflow-visible {
  overflow: visible !important; }

.pd-f-stack-icon {
  font-family: "SSStandard" !important; }

.pd-f-future-book {
  font-family: "FuturaBT-Book", Arial !important; }

.pd-f-classical {
  font-family: "ClassicalGaramondBT-Italic", serif !important; }

.pd-f-classical-normal {
  font-family: "ClassicalGaramondBT-Roman", serif !important; }

.pd-f-neue {
  font-family: "NeueHaasUnicaPro-Regular", Arial !important; }

.pd-f-neue-medium {
  font-family: "NeueHaasUnicaPro-Medium", Arial !important; }

.pd-f-neue-bold {
  font-family: "NeueHaasUnicaPro-Bold", Arial !important; }

.pd-f-neue-med {
  font-family: "NeueHaasUnicaPro-Medium", "NeueHaasUnicaPro-Regular", Arial !important; }

.pd-f-icon {
  font-family: "icomoon" !important; }

.pd-f-avenir {
  font-family: "Avenir" !important; }

.pd-normal {
  font-style: "normal" !important; }

@media only screen and (min-width: 768px) {
  .pd-md-normal {
    font-size: "normal" !important; } }

.pd-italic {
  font-style: "italic" !important; }

@media only screen and (min-width: 768px) {
  .pd-md-italic {
    font-size: "italic" !important; } }

.pd-oblique {
  font-style: "oblique" !important; }

@media only screen and (min-width: 768px) {
  .pd-md-oblique {
    font-size: "oblique" !important; } }

.pd-initial {
  font-style: "initial" !important; }

@media only screen and (min-width: 768px) {
  .pd-md-initial {
    font-size: "initial" !important; } }

.pd-inherit {
  font-style: "inherit" !important; }

@media only screen and (min-width: 768px) {
  .pd-md-inherit {
    font-size: "inherit" !important; } }

.pd-0 {
  font-size: 0 !important; }

@media only screen and (min-width: 768px) {
  .pd-md-0 {
    font-size: 0 !important; } }

.pd-1px {
  font-size: 1px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-1px {
    font-size: 1px !important; } }

.pd-2px {
  font-size: 2px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-2px {
    font-size: 2px !important; } }

.pd-3px {
  font-size: 3px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-3px {
    font-size: 3px !important; } }

.pd-4px {
  font-size: 4px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-4px {
    font-size: 4px !important; } }

.pd-5px {
  font-size: 5px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-5px {
    font-size: 5px !important; } }

.pd-6px {
  font-size: 6px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-6px {
    font-size: 6px !important; } }

.pd-7px {
  font-size: 7px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-7px {
    font-size: 7px !important; } }

.pd-8px {
  font-size: 8px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-8px {
    font-size: 8px !important; } }

.pd-9px {
  font-size: 9px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-9px {
    font-size: 9px !important; } }

.pd-10px {
  font-size: 10px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-10px {
    font-size: 10px !important; } }

.pd-11px {
  font-size: 11px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-11px {
    font-size: 11px !important; } }

.pd-12px {
  font-size: 12px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-12px {
    font-size: 12px !important; } }

.pd-13px {
  font-size: 13px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-13px {
    font-size: 13px !important; } }

.pd-14px {
  font-size: 14px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-14px {
    font-size: 14px !important; } }

.pd-15px {
  font-size: 15px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-15px {
    font-size: 15px !important; } }

.pd-16px {
  font-size: 16px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-16px {
    font-size: 16px !important; } }

.pd-17px {
  font-size: 17px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-17px {
    font-size: 17px !important; } }

.pd-18px {
  font-size: 18px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-18px {
    font-size: 18px !important; } }

.pd-19px {
  font-size: 19px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-19px {
    font-size: 19px !important; } }

.pd-20px {
  font-size: 20px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-20px {
    font-size: 20px !important; } }

.pd-22px {
  font-size: 22px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-22px {
    font-size: 22px !important; } }

.pd-24px {
  font-size: 24px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-24px {
    font-size: 24px !important; } }

.pd-26px {
  font-size: 26px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-26px {
    font-size: 26px !important; } }

.pd-28px {
  font-size: 28px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-28px {
    font-size: 28px !important; } }

.pd-30px {
  font-size: 30px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-30px {
    font-size: 30px !important; } }

.pd-32px {
  font-size: 32px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-32px {
    font-size: 32px !important; } }

.pd-34px {
  font-size: 34px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-34px {
    font-size: 34px !important; } }

.pd-36px {
  font-size: 36px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-36px {
    font-size: 36px !important; } }

.pd-38px {
  font-size: 38px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-38px {
    font-size: 38px !important; } }

.pd-40px {
  font-size: 40px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-40px {
    font-size: 40px !important; } }

.pd-42px {
  font-size: 42px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-42px {
    font-size: 42px !important; } }

.pd-44px {
  font-size: 44px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-44px {
    font-size: 44px !important; } }

.pd-46px {
  font-size: 46px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-46px {
    font-size: 46px !important; } }

.pd-48px {
  font-size: 48px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-48px {
    font-size: 48px !important; } }

.pd-50px {
  font-size: 50px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-50px {
    font-size: 50px !important; } }

.pd-52px {
  font-size: 52px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-52px {
    font-size: 52px !important; } }

.pd-54px {
  font-size: 54px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-54px {
    font-size: 54px !important; } }

.pd-56px {
  font-size: 56px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-56px {
    font-size: 56px !important; } }

.pd-58px {
  font-size: 58px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-58px {
    font-size: 58px !important; } }

.pd-60px {
  font-size: 60px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-60px {
    font-size: 60px !important; } }

.pd-62px {
  font-size: 62px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-62px {
    font-size: 62px !important; } }

.pd-64px {
  font-size: 64px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-64px {
    font-size: 64px !important; } }

.pd-66px {
  font-size: 66px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-66px {
    font-size: 66px !important; } }

.pd-68px {
  font-size: 68px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-68px {
    font-size: 68px !important; } }

.pd-70px {
  font-size: 70px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-70px {
    font-size: 70px !important; } }

.pd-lh-0 {
  line-height: 0 !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-0 {
    line-height: 0 !important; } }

.pd-lh-1px {
  line-height: 1px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-1px {
    line-height: 1px !important; } }

.pd-lh-2px {
  line-height: 2px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-2px {
    line-height: 2px !important; } }

.pd-lh-3px {
  line-height: 3px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-3px {
    line-height: 3px !important; } }

.pd-lh-4px {
  line-height: 4px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-4px {
    line-height: 4px !important; } }

.pd-lh-5px {
  line-height: 5px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-5px {
    line-height: 5px !important; } }

.pd-lh-6px {
  line-height: 6px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-6px {
    line-height: 6px !important; } }

.pd-lh-7px {
  line-height: 7px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-7px {
    line-height: 7px !important; } }

.pd-lh-8px {
  line-height: 8px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-8px {
    line-height: 8px !important; } }

.pd-lh-9px {
  line-height: 9px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-9px {
    line-height: 9px !important; } }

.pd-lh-10px {
  line-height: 10px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-10px {
    line-height: 10px !important; } }

.pd-lh-11px {
  line-height: 11px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-11px {
    line-height: 11px !important; } }

.pd-lh-12px {
  line-height: 12px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-12px {
    line-height: 12px !important; } }

.pd-lh-13px {
  line-height: 13px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-13px {
    line-height: 13px !important; } }

.pd-lh-14px {
  line-height: 14px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-14px {
    line-height: 14px !important; } }

.pd-lh-15px {
  line-height: 15px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-15px {
    line-height: 15px !important; } }

.pd-lh-16px {
  line-height: 16px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-16px {
    line-height: 16px !important; } }

.pd-lh-17px {
  line-height: 17px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-17px {
    line-height: 17px !important; } }

.pd-lh-18px {
  line-height: 18px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-18px {
    line-height: 18px !important; } }

.pd-lh-19px {
  line-height: 19px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-19px {
    line-height: 19px !important; } }

.pd-lh-20px {
  line-height: 20px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-20px {
    line-height: 20px !important; } }

.pd-lh-22px {
  line-height: 22px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-22px {
    line-height: 22px !important; } }

.pd-lh-24px {
  line-height: 24px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-24px {
    line-height: 24px !important; } }

.pd-lh-26px {
  line-height: 26px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-26px {
    line-height: 26px !important; } }

.pd-lh-28px {
  line-height: 28px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-28px {
    line-height: 28px !important; } }

.pd-lh-30px {
  line-height: 30px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-30px {
    line-height: 30px !important; } }

.pd-lh-32px {
  line-height: 32px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-32px {
    line-height: 32px !important; } }

.pd-lh-34px {
  line-height: 34px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-34px {
    line-height: 34px !important; } }

.pd-lh-36px {
  line-height: 36px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-36px {
    line-height: 36px !important; } }

.pd-lh-38px {
  line-height: 38px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-38px {
    line-height: 38px !important; } }

.pd-lh-40px {
  line-height: 40px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-40px {
    line-height: 40px !important; } }

.pd-lh-42px {
  line-height: 42px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-42px {
    line-height: 42px !important; } }

.pd-lh-44px {
  line-height: 44px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-44px {
    line-height: 44px !important; } }

.pd-lh-46px {
  line-height: 46px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-46px {
    line-height: 46px !important; } }

.pd-lh-48px {
  line-height: 48px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-48px {
    line-height: 48px !important; } }

.pd-lh-50px {
  line-height: 50px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-50px {
    line-height: 50px !important; } }

.pd-lh-52px {
  line-height: 52px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-52px {
    line-height: 52px !important; } }

.pd-lh-54px {
  line-height: 54px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-54px {
    line-height: 54px !important; } }

.pd-lh-56px {
  line-height: 56px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-56px {
    line-height: 56px !important; } }

.pd-lh-58px {
  line-height: 58px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-58px {
    line-height: 58px !important; } }

.pd-lh-60px {
  line-height: 60px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-60px {
    line-height: 60px !important; } }

.pd-lh-62px {
  line-height: 62px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-62px {
    line-height: 62px !important; } }

.pd-lh-64px {
  line-height: 64px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-64px {
    line-height: 64px !important; } }

.pd-lh-66px {
  line-height: 66px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-66px {
    line-height: 66px !important; } }

.pd-lh-68px {
  line-height: 68px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-68px {
    line-height: 68px !important; } }

.pd-lh-70px {
  line-height: 70px !important; }

@media only screen and (min-width: 768px) {
  .pd-md-lh-70px {
    line-height: 70px !important; } }

.experience-component .ex-button {
  border: 1px solid #000;
  color: #000;
  background: transparent;
  padding: 12px 30px;
  text-transform: none;
  min-width: 136px;
  height: auto;
  font-family: "Avenir";
  text-align: center; }
  .experience-component .ex-button:hover {
    background: #000;
    color: #fff;
    text-decoration: none; }
  [class*="--dark"] .experience-component .ex-button {
    border: 1px solid #fff;
    color: #fff;
    background: "transparent";
    padding: 12px 30px;
    text-transform: none;
    min-width: 136px;
    height: auto;
    font-family: "Avenir"; }
    [class*="--dark"] .experience-component .ex-button:hover {
      background: #fff;
      color: #000;
      text-decoration: none; }

.experience-component .ex-button--dark {
  border: 1px solid #fff;
  color: #fff;
  background: #000;
  padding: 12px 30px;
  text-transform: none;
  min-width: 136px;
  height: auto;
  font-family: "Avenir"; }
  .experience-component .ex-button--dark:hover {
    background: #fff;
    color: #000;
    text-decoration: none; }

.experience-component .ex-button--inverse {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  padding: 12px 30px;
  text-transform: none;
  min-width: 136px;
  height: auto;
  font-family: "Avenir"; }
  .experience-component .ex-button--inverse:hover {
    background: #000;
    color: #fff;
    text-decoration: none; }

@media only screen and (max-width: 767px) {
  .experience-component .ex-button--round-m {
    border-radius: 50px; } }

@media only screen and (min-width: 768px) {
  .experience-component .ex-button--round-md {
    border-radius: 50px; } }

.experience-component .ex-button-plus-minus {
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: visible;
  height: 25px;
  padding: 0;
  width: 25px; }
  .experience-component .ex-button-plus-minus:hover {
    background-color: #000; }
    .experience-component .ex-button-plus-minus:hover::before, .experience-component .ex-button-plus-minus:hover::after {
      border-color: #fff; }
  .experience-component .ex-button-plus-minus:focus {
    outline: none; }
  .experience-component .ex-button-plus-minus::before {
    border-top: 1px #000 solid;
    content: " ";
    display: inline-block;
    height: 1px;
    left: 6px;
    position: absolute;
    top: 11px;
    width: 11px; }
  .experience-component .ex-button-plus-minus::after {
    border-left: 1px #000 solid;
    content: " ";
    display: inline-block;
    height: 11px;
    left: 11px;
    position: absolute;
    top: 6px;
    width: 1px; }
  [class*="--dark"] .experience-component .ex-button-plus-minus {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    overflow: visible;
    height: 25px;
    padding: 0;
    width: 25px; }
    [class*="--dark"] .experience-component .ex-button-plus-minus:hover {
      background-color: #fff; }
      [class*="--dark"] .experience-component .ex-button-plus-minus:hover::before, [class*="--dark"] .experience-component .ex-button-plus-minus:hover::after {
        border-color: #000; }
    [class*="--dark"] .experience-component .ex-button-plus-minus:focus {
      outline: none; }
    [class*="--dark"] .experience-component .ex-button-plus-minus::before {
      border-top: 1px #fff solid;
      content: " ";
      display: inline-block;
      height: 1px;
      left: 6px;
      position: absolute;
      top: 11px;
      width: 11px; }
    [class*="--dark"] .experience-component .ex-button-plus-minus::after {
      border-left: 1px #fff solid;
      content: " ";
      display: inline-block;
      height: 11px;
      left: 11px;
      position: absolute;
      top: 6px;
      width: 1px; }

.experience-component .ex-button-plus-minus--dark {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: visible;
  height: 25px;
  padding: 0;
  width: 25px; }
  .experience-component .ex-button-plus-minus--dark:hover {
    background-color: #fff; }
    .experience-component .ex-button-plus-minus--dark:hover::before, .experience-component .ex-button-plus-minus--dark:hover::after {
      border-color: #000; }
  .experience-component .ex-button-plus-minus--dark:focus {
    outline: none; }
  .experience-component .ex-button-plus-minus--dark::before {
    border-top: 1px #fff solid;
    content: " ";
    display: inline-block;
    height: 1px;
    left: 6px;
    position: absolute;
    top: 11px;
    width: 11px; }
  .experience-component .ex-button-plus-minus--dark::after {
    border-left: 1px #fff solid;
    content: " ";
    display: inline-block;
    height: 11px;
    left: 11px;
    position: absolute;
    top: 6px;
    width: 1px; }

.experience-component .ex-button-link {
  border-bottom: 1px #000 solid;
  color: #000;
  font: normal normal 12px/18px "Avenir";
  letter-spacing: normal; }
  .experience-component .ex-button-link:hover, .experience-component .ex-button-link:focus, .experience-component .ex-button-link.active {
    border-color: transparent;
    color: #000;
    text-decoration: none; }
  [class*="--dark"] .experience-component .ex-button-link {
    border-bottom: 1px #fff solid;
    color: #fff;
    font: normal normal 12px/18px "Avenir";
    letter-spacing: normal; }
    [class*="--dark"] .experience-component .ex-button-link:hover, [class*="--dark"] .experience-component .ex-button-link:focus, [class*="--dark"] .experience-component .ex-button-link.active {
      border-color: transparent;
      color: #fff;
      text-decoration: none; }

.experience-component .ex-button-link--dark {
  border-bottom: 1px #fff solid;
  color: #fff;
  font: normal normal 12px/18px "Avenir";
  letter-spacing: normal; }
  .experience-component .ex-button-link--dark:hover, .experience-component .ex-button-link--dark:focus, .experience-component .ex-button-link--dark.active {
    border-color: transparent;
    color: #fff;
    text-decoration: none; }

.experience-component .ex-button-link--float {
  left: 44px;
  position: absolute;
  top: 0;
  white-space: nowrap; }

.experience-component .ex-button-min-width-136 {
  min-width: 136px; }

.experience-component .ex-button-play {
  width: 50px;
  height: 50px;
  border-style: solid;
  box-sizing: border-box;
  border-width: 25px 0 25px 50px;
  background-color: transparent;
  border-color: transparent transparent transparent #fff;
  padding: 0;
  opacity: 0.7; }
  .experience-component .ex-button-play:focus {
    outline: none; }
  .experience-component .ex-button-play:hover, .experience-component .ex-button-play:focus {
    opacity: 1; }

.experience-component .ex-button-play--dark {
  width: 50px;
  height: 50px;
  border-style: solid;
  box-sizing: border-box;
  border-width: 25px 0 25px 50px;
  background-color: transparent;
  border-color: transparent transparent transparent #000;
  padding: 0;
  opacity: 0.7; }
  .experience-component .ex-button-play--dark:focus {
    outline: none; }
  .experience-component .ex-button-play--dark:hover, .experience-component .ex-button-play--dark:focus {
    opacity: 1; }

.experience-component a.disabled {
  cursor: default;
  background: #ccc;
  border-color: #ccc;
  color: #54584e; }
  .experience-component a.disabled:hover, .experience-component a.disabled:active, .experience-component a.disabled:focus {
    background: #ccc;
    border-color: #ccc;
    color: #54584e; }

.page-designer-reference .button-primary {
  text-align: center;
  border: 1px solid #000;
  color: #fff;
  background: #000;
  padding: 12px 30px;
  text-transform: none;
  min-width: 136px;
  height: auto;
  font-family: "Avenir"; }
  .page-designer-reference .button-primary:hover {
    background: #fff;
    color: #000;
    text-decoration: none; }

.page-designer-reference .button-dark {
  text-align: center;
  border: 1px solid #000;
  color: #000;
  background: transparent;
  padding: 12px 30px;
  text-transform: none;
  min-width: 136px;
  height: auto;
  font-family: "Avenir"; }
  .page-designer-reference .button-dark:hover {
    background: #000;
    color: #fff;
    text-decoration: none; }

.page-designer-reference .button-dark-2 {
  border: 1px solid #979797;
  color: #3f3f3f;
  background: transparent;
  padding: 12px 30px;
  text-transform: none;
  min-width: 136px;
  height: auto;
  font-family: "Avenir";
  text-align: center; }
  .page-designer-reference .button-dark-2:hover {
    background: #3f3f3f;
    color: #fff;
    text-decoration: none; }

.page-designer-reference .link-dark {
  border-bottom: 1px #000 solid;
  color: #000;
  font: normal normal 12px/18px "Avenir";
  letter-spacing: normal; }
  .page-designer-reference .link-dark:hover, .page-designer-reference .link-dark:focus, .page-designer-reference .link-dark.active {
    border-color: transparent;
    color: #000;
    text-decoration: none; }

.page-designer-reference .link-dark--black-hover-space {
  border-bottom: 1px transparent solid;
  color: #000;
  font: normal normal 12px/18px "Avenir";
  letter-spacing: 2.6px; }
  .page-designer-reference .link-dark--black-hover-space:hover, .page-designer-reference .link-dark--black-hover-space:focus, .page-designer-reference .link-dark--black-hover-space.active {
    border-color: #000;
    color: #000;
    text-decoration: none; }

.page-designer-reference .link-light--white-hover-space {
  border-bottom: 1px transparent solid;
  color: #fff;
  font: normal normal 12px/18px "Avenir";
  letter-spacing: 2.6px; }
  .page-designer-reference .link-light--white-hover-space:hover, .page-designer-reference .link-light--white-hover-space:focus, .page-designer-reference .link-light--white-hover-space.active {
    border-color: #fff;
    color: #fff;
    text-decoration: none; }

.page-designer-reference .link-dark--brwon-hover-space {
  border-bottom: 1px transparent solid;
  color: #8b6e38;
  font: normal normal 12px/18px "Avenir";
  letter-spacing: 2.6px; }
  .page-designer-reference .link-dark--brwon-hover-space:hover, .page-designer-reference .link-dark--brwon-hover-space:focus, .page-designer-reference .link-dark--brwon-hover-space.active {
    border-color: #8b6e38;
    color: #8b6e38;
    text-decoration: none; }

.page-designer-reference .link-grey--grey-hover-space {
  border-bottom: 1px transparent solid;
  color: #a1acad;
  font: normal normal 12px/18px "Avenir";
  letter-spacing: 2.6px; }
  .page-designer-reference .link-grey--grey-hover-space:hover, .page-designer-reference .link-grey--grey-hover-space:focus, .page-designer-reference .link-grey--grey-hover-space.active {
    border-color: #a1acad;
    color: #a1acad;
    text-decoration: none; }

.page-designer-reference .button-light {
  text-align: center;
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  padding: 12px 30px;
  text-transform: none;
  min-width: 136px;
  height: auto;
  font-family: "Avenir"; }
  .page-designer-reference .button-light:hover {
    background: #fff;
    color: #000;
    text-decoration: none; }

.page-designer-reference .link-light {
  border-bottom: 1px #fff solid;
  color: #fff;
  font: normal normal 12px/18px "Avenir";
  letter-spacing: normal; }
  .page-designer-reference .link-light:hover, .page-designer-reference .link-light:focus, .page-designer-reference .link-light.active {
    border-color: transparent;
    color: #fff;
    text-decoration: none; }

.page-designer-reference .ex-slider-counter {
  bottom: 1px;
  color: #000;
  font: normal normal 16px/18px "ClassicalGaramondBT-Roman", serif;
  position: absolute;
  right: 31px; }
  @media only screen and (min-width: 1024px) {
    .page-designer-reference .ex-slider-counter {
      margin-right: -15px;
      right: 50%; } }

.experience-component .ex-shadow--inner-img {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .experience-component .ex-shadow--inner-img::after {
    content: "";
    position: absolute;
    box-shadow: inset 0 -150px 100px -100px #000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    /* You can use higher to ensure that it stays on top */ }

.page-designer-reference .carousel-indicators:not(.indicators--full) {
  bottom: 0; }
  .page-designer-reference .carousel-indicators:not(.indicators--full) li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: transparent;
    border: 1px solid #fff;
    margin-right: 7px;
    margin-left: 7px; }
    .page-designer-reference .carousel-indicators:not(.indicators--full) li.active {
      background-color: #fff; }

.page-designer-reference .carousel-indicators.indicators--full li {
  width: auto;
  height: 3px;
  background-color: #d4d4d4;
  border: none;
  margin-right: 0;
  margin-left: 0;
  flex: 1; }
  .page-designer-reference .carousel-indicators.indicators--full li.active {
    background-color: #fff; }

.page-designer-reference .carousel-indicators.indicators--black li {
  background-color: #dbdbdb; }
  .page-designer-reference .carousel-indicators.indicators--black li.active {
    background-color: #000; }

.page-designer-reference .component-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: var(--focal-point-x-m) var(--focal-point-y-m);
  border-radius: var(--border-radius-m); }

.region_landscape-large::before,
.region_landscape-small::before,
.region_square::before,
.region_portrait-small::before {
  content: none; }

@media screen and (min-width: 768px) {
  .page-designer-reference .component-image {
    object-position: var(--focal-point-x) var(--focal-point-y);
    border-radius: var(--border-radius); } }

#wrapper.pt_content #main {
  max-width: 1440px; }
  #wrapper.pt_content #main.default-max-width {
    max-width: none; }

.experience-component {
  max-width: 100%;
  max-height: 100%; }

.experience-component .ex-text-snippet {
  color: #000; }
  .experience-component .ex-text-snippet p {
    font: normal normal 16px/28px "Avenir"; }
  [class*="--dark"] .experience-component .ex-text-snippet {
    color: #fff; }

.experience-component {
  /* Title */
  /* Title -- dark */
  /* Sub Title */
  /* Sub Title -- dark */
  /* Sub Title -- Border */
  /* Tile Title */
  /* Tile Title -- dark */ }
  .experience-component .ex-heading {
    color: #000;
    font: normal normal 36px/36px "ClassicalGaramondBT-Roman", serif;
    text-transform: none;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .experience-component .ex-heading {
        font: normal normal 50px/50px "ClassicalGaramondBT-Roman", serif; } }
    [class*="--dark"] .experience-component .ex-heading {
      color: #fff; }
  .experience-component .ex-heading--dark {
    color: #fff; }
  .experience-component .ex-heading-sub {
    color: #000;
    font: normal normal 24px/24px "ClassicalGaramondBT-Roman", serif;
    text-transform: none; }
    @media only screen and (min-width: 768px) {
      .experience-component .ex-heading-sub {
        font: normal normal 30px/30px "ClassicalGaramondBT-Roman", serif; } }
    [class*="--dark"] .experience-component .ex-heading-sub {
      color: #fff; }
  .experience-component .ex-heading-sub--dark {
    color: #fff; }
  .experience-component .ex-heading-sub--border::after {
    border-bottom: 1px #000 solid;
    content: " ";
    display: block;
    margin-top: 27px;
    width: 50px; }
  [class*="--dark"] .experience-component .ex-heading-sub--border::after {
    border-color: #fff; }
  .experience-component .ex-heading-tile {
    color: #000;
    font: normal normal 24px/24px "ClassicalGaramondBT-Roman", serif;
    text-transform: none; }
    @media only screen and (min-width: 768px) {
      .experience-component .ex-heading-tile {
        font: normal normal 30px/30px "ClassicalGaramondBT-Roman", serif; } }
    [class*="--dark"] .experience-component .ex-heading-tile {
      color: #fff; }
  .experience-component .ex-heading-tile--dark {
    color: #fff; }

.experience-component {
  /* Collection Tile */ }
  .experience-component .ex-collection-tile__heading {
    color: var(--color);
    letter-spacing: var(--letter-spacing); }
  .experience-component .ex-collection-tile__actions {
    display: flex; }
  .experience-component .ex-collection-tile a {
    color: var(--color);
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    width: var(--width); }
    .experience-component .ex-collection-tile a:hover {
      color: var(--color-hover);
      background-color: var(--background-color-hover); }
    .experience-component .ex-collection-tile a:active {
      color: var(--color-active);
      background-color: var(--background-color-active); }
    @media only screen and (min-width: 768px) {
      .experience-component .ex-collection-tile a {
        width: var(--width-md); } }

.light-color {
  color: #fff; }

.dark-color {
  color: #000; }

.h1-component-title.light-color,
.h2-component-title.light-color,
.h2-component-mobile-large-title.light-color,
.h3-component-title.light-color,
.paragraph-component.light-color {
  color: #fff; }

.h1-component-title.dark-color,
.h2-component-title.dark-color,
.h2-component-mobile-large-title.dark-color,
.h3-component-title.dark-color,
.paragraph-component.dark-color {
  color: #000; }

.h1-component-title,
.h2-component-title,
.h2-component-mobile-large-title,
.h3-component-title {
  font-family: "ClassicalGaramondBT-Roman", serif !important; }

.h1-component-title {
  font-weight: 400;
  font-size: 36px !important;
  line-height: 36px !important; }
  @media only screen and (min-width: 768px) {
    .h1-component-title {
      font-size: 50px !important;
      line-height: 50px !important; } }

.h2-component-title {
  font-weight: 400;
  font-size: 24px !important;
  line-height: 24px !important; }
  @media only screen and (min-width: 768px) {
    .h2-component-title {
      font-size: 30px !important;
      line-height: 30px !important; } }

.h2-component-title-secondary {
  font-family: "ClassicalGaramondBT-Italic", serif !important;
  font-size: 26px !important;
  line-height: 24px !important; }
  @media only screen and (min-width: 768px) {
    .h2-component-title-secondary {
      font-size: 26px !important;
      line-height: 30px !important; } }

.h2-component-mobile-large-title {
  font-weight: 400;
  font-size: 30px !important;
  line-height: 30px !important; }

.h3-component-title {
  font-weight: 400;
  font-size: 20px !important;
  line-height: 24px !important; }
  @media only screen and (min-width: 768px) {
    .h3-component-title {
      font-size: 20px !important;
      line-height: 24px !important; } }

p,
span {
  font-family: "Avenir"; }

.paragraph-component {
  font-family: "Avenir" !important;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 28px !important; }

.paragraph-small-component {
  font-family: "Avenir";
  font-weight: 400;
  font-size: 12px !important;
  line-height: 18px !important; }

.paragraph-mobile-small-component {
  font-family: "Avenir";
  font-weight: 400;
  font-size: 12px !important;
  line-height: 18px !important; }
  @media only screen and (min-width: 768px) {
    .paragraph-mobile-small-component {
      font-size: 16px !important;
      line-height: 28px !important; } }

.clearfix::after {
  content: "";
  display: table;
  clear: both; }

/*  To utilize the font mixin,

@mixin font-source-serif($size: false, $color: false, $weight: false,  $lh: false, $ls: false) {
@mixin font-source-serif-italic($size: false, $color: false, $weight: false,  $lh: false, $ls: false) {
@mixin font-source-sans($size: false, $color: false, $weight: false,  $lh: false, $ls: false) {

// @include font-source-serif(40px, $brand-v2-off-black, normal, 50px, null);
// @include font-source-sans(16px, $brand-v2-off-black, normal, 21px, 0.03em);

*/
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px; }

.display-none {
  display: none; }

.menu {
  float: left;
  margin: 0;
  padding: 0; }
  .menu li {
    float: left;
    list-style: none outside none !important; }

.text-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 99%; }

/* mixin for multiline */
.experience-component {
  /* Collection Tile */ }
  .experience-component .ex-product-item__image {
    background: #fff;
    border-radius: 0 10px;
    min-height: 140px; }
  .experience-component .ex-product-item__info {
    border-radius: var(--border-radius);
    background: #fbfbfb;
    border: 1px #e7e7e7 solid;
    border-radius: 10px 0;
    height: 100%;
    display: none;
    left: 0;
    top: 0;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .experience-component .ex-product-item__info {
        border-radius: var(--border-radius-m); } }
    .experience-component .ex-product-item__info .product-tile .product-image {
      width: 50%; }
    .experience-component .ex-product-item__info .product-tile .default-image {
      width: 100%; }
    .experience-component .ex-product-item__info .product-tile .hidden,
    .experience-component .ex-product-item__info .product-tile .customization-badging,
    .experience-component .ex-product-item__info .product-tile .product-promo,
    .experience-component .ex-product-item__info .product-tile form {
      display: none; }
    .experience-component .ex-product-item__info .product-tile .name-link {
      color: #000;
      display: block;
      font: normal normal 14px/14px "ClassicalGaramondBT-Roman", serif;
      margin-bottom: 4px;
      margin-top: 2px;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-transform: lowercase; }
      @media only screen and (min-width: 768px) {
        .experience-component .ex-product-item__info .product-tile .name-link {
          font: normal normal 20px/24px "ClassicalGaramondBT-Roman", serif; } }
      .experience-component .ex-product-item__info .product-tile .name-link::first-line {
        text-transform: capitalize; }
    .experience-component .ex-product-item__info .product-tile .product-pricing {
      color: #595959;
      font: normal normal 10px/12px "Avenir";
      letter-spacing: -0.5px;
      white-space: wrap; }
      @media only screen and (min-width: 1200px) {
        .experience-component .ex-product-item__info .product-tile .product-pricing {
          font: normal normal 12px/18px "Avenir";
          letter-spacing: 0; } }
      .experience-component .ex-product-item__info .product-tile .product-pricing .product-standard-price.is-on-sale {
        text-decoration: line-through; }
      .experience-component .ex-product-item__info .product-tile .product-pricing .product-sales-price.is-active {
        color: #ae1e1e; }
    .experience-component .ex-product-item__info .product-tile .swatch-list {
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
      padding: 0; }
      @media only screen and (min-width: 1127px) {
        .experience-component .ex-product-item__info .product-tile .swatch-list {
          margin-top: 6px; } }
      @media only screen and (min-width: 1200px) {
        .experience-component .ex-product-item__info .product-tile .swatch-list {
          margin-top: 10px; } }
      .experience-component .ex-product-item__info .product-tile .swatch-list li {
        display: inline-block;
        font: normal normal 12px/18px "Avenir";
        margin-right: 3px; }
        @media only screen and (min-width: 768px) {
          .experience-component .ex-product-item__info .product-tile .swatch-list li {
            display: none;
            margin-right: 10px; } }
        @media only screen and (min-width: 1200px) {
          .experience-component .ex-product-item__info .product-tile .swatch-list li {
            margin-right: 20px; } }
        .experience-component .ex-product-item__info .product-tile .swatch-list li.desktop-swatch, .experience-component .ex-product-item__info .product-tile .swatch-list li.desktop-count {
          display: none; }
          @media only screen and (min-width: 768px) {
            .experience-component .ex-product-item__info .product-tile .swatch-list li.desktop-swatch, .experience-component .ex-product-item__info .product-tile .swatch-list li.desktop-count {
              display: inline-block; } }
        .experience-component .ex-product-item__info .product-tile .swatch-list li.additional-swatch-count {
          order: 2; }
        .experience-component .ex-product-item__info .product-tile .swatch-list li .swatch {
          border-radius: 50%;
          display: block;
          height: 15px;
          margin: 0;
          width: 15px; }
        .experience-component .ex-product-item__info .product-tile .swatch-list li .swatch-image {
          display: block;
          width: 100%; }

/* Modifiers
============================================================================ */
.ex-product-item--active .ex-product-item__info {
  display: block; }

.ex-product-item--active .ex-button-plus-minus::after {
  display: none; }

.experience-component.experience-layouts-flexbox {
  flex: 1; }

.experience-component .ex-image--small-d {
  height: auto;
  width: auto; }

.experience-component .ex-image--large-d {
  height: auto;
  width: auto; }

@media screen and (min-width: 768px) {
  .experience-component .ex-image--small-d {
    height: 337px;
    width: 237px;
    max-width: 100%;
    max-height: 100%; }
  .experience-component .ex-image--large-d {
    height: 742px;
    width: 540px;
    max-width: 100%;
    max-height: 100%; } }

.pd-spacer {
  height: var(--height); }
  @media only screen and (min-width: 768px) {
    .pd-spacer {
      height: var(--heightDesktop); } }

.experience-component .custom-gridcomponentlayout {
  margin: auto;
  width: 100%;
  max-width: var(--max-width); }
  @media only screen and (min-width: 768px) {
    .experience-component .custom-gridcomponentlayout {
      max-width: var(--max-width-md); } }

.experience-component .custom-griditem {
  height: 100%;
  min-height: var(--min-height); }
  .experience-component .custom-griditem__components {
    display: flex;
    height: 100%;
    min-height: var(--min-height-md); }
