@import "../../../../ba_gp_storefront_core/cartridge/scss/default/mixin/breakpoint";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/variables";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/blueacorn/var";

$ex: ex-product-item;

.experience-component {
    /* Collection Tile */
    .#{$ex} {
        &__image {
            background: $white;
            border-radius: 0 10px;
            min-height: 140px;
        }

        &__info {
            border-radius: var(--border-radius);
            background: $collection-bg;
            border: 1px $collection-border solid;
            border-radius: 10px 0;
            height: 100%;
            display: none;
            left: 0;
            top: 0;
            width: 100%;

            @include bp(min-width, $bp-medium) {
                border-radius: var(--border-radius-m);
            }

            // Product Tile
            .product-tile {
                .product-image {
                    width: 50%;
                }

                .default-image {
                    width: 100%;
                }

                .hidden,
                .customization-badging,
                .product-promo,
                form {
                    display: none;
                }

                .name-link {
                    color: $black;
                    display: block;
                    font: normal normal 14px/14px $f-classical-normal;
                    margin-bottom: 4px;
                    margin-top: 2px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-transform: lowercase;

                    @include bp(min-width, $bp-medium) {
                        font: normal normal 20px/24px $f-classical-normal;
                    }

                    &::first-line {
                        text-transform: capitalize;
                    }
                }

                .product-pricing {
                    color: $tile-price;
                    font: normal normal 10px/12px $f-primary;
                    letter-spacing: -0.5px;
                    white-space: wrap;

                    @include bp(min-width, $bp-xlarge) {
                        font: normal normal 12px/18px $f-primary;
                        letter-spacing: 0;
                    }

                    .product-standard-price {
                        &.is-on-sale {
                            text-decoration: line-through;
                        }
                    }

                    .product-sales-price {
                        &.is-active {
                            color: $sale-price;
                        }
                    }
                }

                .swatch-list {
                    display: flex;
                    flex-wrap: nowrap;
                    margin: 0;
                    padding: 0;

                    @include bp(min-width, $bp-custom-large) {
                        margin-top: 6px;
                    }

                    @include bp(min-width, $bp-xlarge) {
                        margin-top: 10px;
                    }

                    li {
                        display: inline-block;
                        font: normal normal 12px/18px $f-primary;
                        margin-right: 3px;

                        @include bp(min-width, $bp-medium) {
                            display: none;
                            margin-right: 10px;
                        }

                        @include bp(min-width, $bp-xlarge) {
                            margin-right: 20px;
                        }

                        &.desktop-swatch,
                        &.desktop-count {
                            display: none;

                            @include bp(min-width, $bp-medium) {
                                display: inline-block;
                            }
                        }

                        &.additional-swatch-count {
                            order: 2;
                        }

                        .swatch {
                            border-radius: 50%;
                            display: block;
                            height: 15px;
                            margin: 0;
                            width: 15px;
                        }

                        .swatch-image {
                            display: block;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

/* Modifiers
============================================================================ */

.#{$ex}--active {
    .#{$ex}__info {
        display: block;
    }

    .ex-button-plus-minus {
        &::after {
            display: none;
        }
    }
}
