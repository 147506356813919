@import "../../../../../ba_gp_storefront_core/cartridge/scss/default/variables";

// Colors
$shop-gallery-light: #faf7f3;
$shop-gallery-dark: #232f2b;
$collection-bg: #fbfbfb;
$collection-border: #e7e7e7;
$headers-color-light: #fff;
$headers-color-dark: #000;
$tile-price: #595959;
$sale-price: #ae1e1e;




//Weights
$font-weight-pd-normal:                400;

// Headings
// h1 font-size and line-height
$h1-pd-font-size-mobile:               36px;
$h1-pd-line-height-mobile:             36px;
$h1-pd-font-size-desktop:              50px;
$h1-pd-line-height-desktop:            50px;

// h2 font-size and line-height
$h2-pd-font-size-mobile:               24px;
$h2-pd-line-height-mobile:             24px;
$h2-pd-font-size-desktop:              30px;
$h2-pd-line-height-desktop:            30px;

// h2 secondary font-size
$h2-pd-font-size-mobile-sec:           26px;
$h2-pd-line-height-mobile-sec:         28px;
$h2-pd-font-size-desktop-sec:          26px;
$h2-pd-line-height-mobile-sec:         28px;

// h3 font-size and line-height
$h3-pd-font-size-mobile:               20px;
$h3-pd-line-height-mobile:             24px;
$h3-pd-font-size-desktop:              20px;
$h3-pd-line-height-desktop:            24px;

// paragraph font-size and line-height
$paragraph-pd-font-size:               16px;
$paragraph-pd-line-height:             28px;
$paragraph-pd-font-size-mobile:        12px;
$paragraph-pd-line-height-mobile:      18px;


$spacer-pd: 1rem; // 16px
$spacers: ();
$spacers: map-merge(
    (
        auto: "auto",
        0: 0,
        1: ($spacer-pd * 0.5),
        // 8px
        2: $spacer-pd,
        // 16px
        3: ($spacer-pd * 1.5),
        // 24px
        4: ($spacer-pd * 2),
        // 32px
        5: ($spacer-pd * 2.5),
        // 40px
        6: ($spacer-pd * 3),
        // 48px
        7: ($spacer-pd * 3.5),
        // 56px
        8: ($spacer-pd * 4),
        // 64px
        9: ($spacer-pd * 4.5),
        // 72px
        10: ($spacer-pd * 5),
        // 80px
        11: ($spacer-pd * 5.5),
        // 88px
        12: ($spacer-pd * 6),
        // 96px
        13: ($spacer-pd * 6.5),
        // 104px
        14: ($spacer-pd * 7),
        // 112px
        15: ($spacer-pd * 7.5),
        // 120px
        16: ($spacer-pd * 8),
        // 128px
        17: ($spacer-pd * 8.5),
        // 136px
        18: ($spacer-pd * 9),
        // 144px
        19: ($spacer-pd * 9.5),
        // 152px
        20: ($spacer-pd * 10),
        // 160px
        21: ($spacer-pd * 10.5),
        // 168px
        22: ($spacer-pd * 11),
        // 176px
        23: ($spacer-pd * 11.5),
        // 184px
        24: ($spacer-pd * 12),
        // 192px
        25: ($spacer-pd * 12.5),
        // 200px
        26: ($spacer-pd * 13)
            // 208px
    ),
        $spacers
);

$sides-pd: (top, right, bottom, left);
