$ex: ex-collection-tile;

.experience-component {
    /* Collection Tile */
    .#{$ex} {
        &__heading {
            color: var(--color);
            letter-spacing: var(--letter-spacing);
        }

        &__actions {
            display: flex;
        }

        a {
            color: var(--color);
            background-color: var(--background-color);
            border-radius: var(--border-radius);
            width: var(--width);

            &:hover {
                color: var(--color-hover);
                background-color: var(--background-color-hover);
            }

            &:active {
                color: var(--color-active);
                background-color: var(--background-color-active);
            }

            @include bp(min-width, $bp-medium) {
                width: var(--width-md);
            }
        }
    }
}
