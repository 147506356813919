$ex: ex-image;

.experience-component {
    .#{$ex} {
        &--small-d {
            height: auto;
            width: auto;
        }
        &--large-d {
            height: auto;
            width: auto;
        }
    }
}

@media screen and (min-width: 768px) {
    .experience-component {
        .#{$ex} {
            &--small-d {
                height: 337px;
                width: 237px;
                max-width: 100%;
                max-height: 100%;
            }
            &--large-d {
                height: 742px;
                width: 540px;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
