$fonts-custom: (
    "f-stack-icon": $f-stack-icon,
    "f-future-book": $f-future-book,
    "f-classical": $f-classical,
    "f-classical-normal": $f-classical-normal,
    "f-neue": $f-neue,
    "f-neue-medium": $f-neue-medium,
    "f-neue-bold": $f-neue-bold,
    "f-neue-med": $f-neue-med,
    "f-icon": $f-icon,
    "f-avenir": $f-avenir,
);

$font-style-custom: "normal", "italic", "oblique", "initial", "inherit";

$fonts-sizes-custom: 0, 1px, 2px, 3px, 4px, 5px, 6px, 7px, 8px, 9px, 10px, 11px, 12px, 13px, 14px, 15px, 16px, 17px, 18px, 19px, 20px, 22px, 24px, 26px, 28px, 30px, 32px, 34px, 36px, 38px, 40px, 42px, 44px, 46px, 48px, 50px, 52px, 54px, 56px, 58px, 60px, 62px, 64px, 66px, 68px, 70px;

@each $key, $value in $fonts-custom {
    .pd-#{$key} {
        font-family: $value !important;
    }
}

@each $key in $font-style-custom {
    .pd-#{$key} {
        font-style: $key !important;
    }

    @include bp(min-width, $bp-medium) {
        .pd-md-#{$key} {
            font-size: $key !important;
        }
    }
}

@each $key in $fonts-sizes-custom {
    .pd-#{$key} {
        font-size: $key !important;
    }

    @include bp(min-width, $bp-medium) {
        .pd-md-#{$key} {
            font-size: $key !important;
        }
    }
}

@each $key in $fonts-sizes-custom {
    .pd-lh-#{$key} {
        line-height: $key !important;
    }

    @include bp(min-width, $bp-medium) {
        .pd-md-lh-#{$key} {
            line-height: $key !important;
        }
    }
}
