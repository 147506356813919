$ex: custom-gridcomponentlayout;

.experience-component {
    .#{$ex} {
        margin: auto;
        width: 100%;
        max-width: var(--max-width);

        @include bp(min-width, $bp-medium) {
            max-width: var(--max-width-md);
        }
    }
}
