$ex: ex-button;

.experience-component {
    // Default button
    .#{$ex} {
        @include button;
        text-align: center;

        [class*="--dark"] & {
            @include button(1px solid $white, $white, "transparent", $white, $black);
        }
    }

    // Default button -- dark
    .#{$ex}--dark {
        @include button(1px solid $white, $white, $black, $white, $black);
    }

    .#{$ex}--inverse {
        @include button(1px solid $white, $white);
    }

    .#{$ex}--round-m {
        @include bp(max-width, $bp-medium) {
            border-radius: 50px;
        }
    }

    .#{$ex}--round-md {
        @include bp(min-width, $bp-medium) {
            border-radius: 50px;
        }
    }

    // Plus Minus button
    .#{$ex}-plus-minus {
        @include plus-minus();

        [class*="--dark"] & {
            @include plus-minus(1px solid $white, $white, $black);
        }
    }

    // Plus Minus button -- dark
    .#{$ex}-plus-minus--dark {
        @include plus-minus(1px solid $white, $white, $black);
    }

    // Link
    .#{$ex}-link {
        @include button-link();

        [class*="--dark"] & {
            @include button-link($white);
        }
    }

    // Link -- dark
    .#{$ex}-link--dark {
        @include button-link($white);
    }

    // Link -- float
    .#{$ex}-link--float {
        left: 44px;
        position: absolute;
        top: 0;
        white-space: nowrap;
    }

    //Button min width
    .#{$ex}-min-width-136 {
        min-width: 136px;
    }

    .#{$ex}-play {
        @include button-play($white);
    }

    .#{$ex}-play--dark {
        @include button-play();
    }

    // Link -- disabled
    a {
        &.disabled {
            cursor: default;
            background: $very-light-gray;
            border-color: $very-light-gray;
            color: $dark-sage;

            &:hover,
            &:active,
            &:focus {
                background: $very-light-gray;
                border-color: $very-light-gray;
                color: $dark-sage;
            }
        }
    }
}

.page-designer-reference {
    .button-primary {
        text-align: center;
        @include button(1px solid $black, $white, $black, $white, $black);
    }

    .button-dark {
        text-align: center;
        @include button();
    }

    .button-dark-2 {
        @include button(1px solid #979797, #3f3f3f, transparent, #3f3f3f, $white);
        text-align: center;
    }

    .link-dark {
        @include button-link();
    }

    .link-dark--black-hover-space {
        @include button-link($black, transparent, $black, $black, 2.6px);
    }

    .link-light--white-hover-space {
        @include button-link($white, transparent, $white, $white, 2.6px);
    }

    .link-dark--brwon-hover-space {
        @include button-link($brown, transparent, $brown, $brown, 2.6px);
    }

    .link-grey--grey-hover-space {
        @include button-link($smoky, transparent, $smoky, $smoky, 2.6px);
    }

    .button-light {
        text-align: center;
        @include button(1px solid $white, $white, transparent, $white, $black);
    }

    .link-light {
        @include button-link($white);
    }

    .ex-slider-counter {
        @include slider-counter($black);
    }
}
