$ex: custom-griditem;

.experience-component {
    .#{$ex} {
        height: 100%;
        min-height: var(--min-height);

        &__components {
            display: flex;
            height: 100%;
            min-height: var(--min-height-md);
        }
    }
}
