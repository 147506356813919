.page-designer-reference {
    .carousel-indicators {
        &:not(.indicators--full) {
            bottom: 0;

            li {
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background-color: transparent;
                border: 1px solid $white;
                margin-right: 7px;
                margin-left: 7px;

                &.active {
                    background-color: $white;
                }
            }
        }

        &.indicators--full {
            li {
                width: auto;
                height: 3px;
                background-color: #d4d4d4;
                border: none;
                margin-right: 0;
                margin-left: 0;
                flex: 1;

                &.active {
                    background-color: $white;
                }
            }
        }

        &.indicators--black {
            li {
                background-color: #dbdbdb;

                &.active {
                    background-color: $black;
                }
            }
        }
    }

    .component-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: var(--focal-point-x-m) var(--focal-point-y-m);
        border-radius: var(--border-radius-m);
    }
}

.region_landscape-large,
.region_landscape-small,
.region_square,
.region_portrait-small {
    &::before {
        content: none;
    }
}

@media screen and (min-width: 768px) {
    .page-designer-reference {
        .component-image {
            object-position: var(--focal-point-x) var(--focal-point-y);
            border-radius: var(--border-radius);
        }
    }
}

#wrapper {
    &.pt_content {
        #main {
            max-width: 1440px;

            &.default-max-width {
                max-width: none;
            }
        }
    }
}

.experience-component {
    max-width: 100%;
    max-height: 100%;
}
