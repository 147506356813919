$ex: ex-text-snippet;

.experience-component {
    // Text snippet
    .#{$ex} {
        color: $black;

        p {
            font: normal normal 16px/28px $f-primary;
        }

        [class*="--dark"] & {
            color: $white;
        }
    }
}
