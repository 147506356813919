$ex: ex-shadow;

.experience-component {
    .#{$ex}--inner-img {
        position: absolute;
        pointer-events: none;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &::after {
            content: "";
            position: absolute;
            box-shadow: inset 0 -150px 100px -100px $black;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1; /* You can use higher to ensure that it stays on top */
        }
    }
}
